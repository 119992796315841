import { Component, OnInit } from "@angular/core";
import {
  ModalController,
  NavParams,
  LoadingController,
  AlertController,
  NavController,
} from "@ionic/angular";
import { UserDataService } from "src/app/providers/userData/user-data.service";
import { UserProfileService } from "src/app/providers/userProfile/user-profile.service";
import { DepartmentService } from "src/app/providers/department/department.service";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-profile-model",
  templateUrl: "./profile-model.page.html",
  styleUrls: ["./profile-model.page.scss"],
})
export class ProfileModelPage implements OnInit {
  userProfile: any = {};
  ADD_DEPARTMENT: boolean = false;
  responsiblity: any = {};
  departments = [];
  metadata: any = {};
  userAsHODList = [];
  criterias = ["I", "II", "III", "IV", "V", "VI", "VII"];
  criteriasFiltered = ["I", "II", "III", "IV", "V", "VI", "VII"];
  allDepartments = [];
  isPrincipalAdded: boolean = false;
  IS_DEPARTMENTMENDATORY: boolean = false;
  IS_CRITERIA_MANDATORY: boolean = false;
  isDefaultDepartment: boolean = false;
  defaultDepartment: String = "";
  loggedInUser: any = {};
  constructor(
    public modalController: ModalController,
    public nvparams: NavParams,
    public loadingController: LoadingController,
    public userdataService: UserDataService,
    public userProfileService: UserProfileService,
    public departmentService: DepartmentService,
    public alertController: AlertController,
    public route: Router
  ) {
    this.userProfile = this.nvparams.get("userProfile");
    this.userAsHODList = this.nvparams.get("hodUser");
    this.isPrincipalAdded = this.nvparams.get("ispRinciPal");
    this.loggedInUser = this.nvparams.get("loggedInUser");
    //console.log(this.userProfile,this.userAsHODList,this.isPrincipalAdded)
  }

  ngOnInit() {
    this.getDepartmentOfCollage();
    this.responsiblity.designation = undefined;
    this.responsiblity.designation = null;
    this.responsiblity.designation = "";
    this.responsiblity.departmentId = undefined;
    this.responsiblity.departmentId = null;
    this.responsiblity.departmentId = "";

    this.userdataService.getMetadata().then((metadat) => {
      this.metadata = metadat;

      this.metadata.disignations.forEach((desg, index) => {
        if (desg.value == "DIRECTOR") {
          this.metadata.disignations.splice(index, 1);
        }
      });

      this.metadata.disignations.forEach((desg, index) => {
        if (desg.value == "SUPER_ADMIN") {
          this.metadata.disignations.splice(index, 1);
        }
      });
      this.metadata.disignations.forEach((desg, index) => {
        if (desg.value == "GOVT_ADMIN") {
          this.metadata.disignations.splice(index, 1);
        }
      });

      this.filterApplicableRoleAndDepartments();
    });
  }

  filterApplicableRoleAndDepartments() {
    this.criteriasFiltered = [];
    const alreadyOwnerOfCriteria = [];
    this.userProfile.responsiblities.forEach((respo) => {
      if (respo.designation == "HEAD_OF_DEPT") {
        this.departments.forEach((dept, index) => {
          this.userAsHODList.forEach((user) => {
            if (user.asHODWithDepartment.id == dept.id) {
              this.departments.splice(index, 1);
            }
          });
        });
      }
      //FACILITATOR
      if (respo.designation == "FACILITATOR") {
        /* this.metadata.disignations.filter((desg)=>{
          desg.value !== "FACILITATOR"
        }); */
        this.metadata.disignations = [];
      } else {
        /* this.metadata.disignations.filter((desg)=>{
          desg.value === "FACILITATOR"
        }) */
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "FACILITATOR") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      //console.log('Updated designations... ', this.metadata.disignations);
      if (respo.designation == "TEACHER") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "TEACHER") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "OFFICE_COORDINATOR") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "OFFICE_COORDINATOR") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "WEBSITE_COORDINATOR") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "WEBSITE_COORDINATOR") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "DIRECTOR") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "DIRECTOR") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }

      if (respo.designation == "PRINCIPAL") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "PRINCIPAL") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }

      if (respo.designation == "VICE_PRINCIPAL") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "VICE_PRINCIPAL") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "IQAC_ADMIN") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "IQAC_ADMIN") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "IQAC_CO_ADMIN") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "IQAC_CO_ADMIN") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "GUEST") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "GUEST") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "COLLAGE_MGMT_REPRESENTATIVE") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "COLLAGE_MGMT_REPRESENTATIVE") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "LIBRARIAN") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "LIBRARIAN") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "PHYSICAL_EDUCATION_SPORTS") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "PHYSICAL_EDUCATION_SPORTS") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "GREEN_COORDINATOR") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "GREEN_COORDINATOR") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "ACADEMIC_AUDITOR") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "ACADEMIC_AUDITOR") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "HEAD_OF_DEPT") {
        this.metadata.disignations.forEach((desg, index) => {
          if (desg.value == "HEAD_OF_DEPT") {
            this.metadata.disignations.splice(index, 1);
          }
        });
      }
      if (respo.designation == "CRITERIA_COORDINATOR") {
        alreadyOwnerOfCriteria.push(respo.criteria);
      }
    });
    this.criterias.forEach((criteria, index) => {
      if (!alreadyOwnerOfCriteria.includes(criteria)) {
        this.criteriasFiltered.push(criteria);
      }
    });
  }

  getDepartmentOfCollage() {
    this.userdataService.getToken().then((token) => {
      if (token) {
        this.userdataService.getUserProfile().then((profile) => {
          if (profile) {
            this.departmentService
              .getAllActiveDepartmentForCollage(token, profile.college.id)
              .subscribe((response) => {
                if (response) {
                  this.departments = response;
                  this.allDepartments = response;
                  //console.log("allDepartments",response);
                }
              });
          }
        });
      }
    });
  }
  designationChange() {
    this.isDefaultDepartment = false;
    this.defaultDepartment = "";
    this.IS_DEPARTMENTMENDATORY = false;
    this.IS_CRITERIA_MANDATORY = false;
    this.departments.forEach((dept, index) => {
      if (dept?.name?.toUpperCase() == "SPORTS") {
        this.departments.splice(index, 1);
      }
    });
    this.departments.forEach((dept, index) => {
      if (dept?.name?.toUpperCase() == "LIBRARY") {
        this.departments.splice(index, 1);
      }
    });
    this.metadata.disignations.forEach((desg) => {
      if (desg.value == this.responsiblity.designation) {
        this.responsiblity.designationLabel = desg.label;
      }
    });
    if (this.responsiblity.designation == "HEAD_OF_DEPT") {
      this.IS_DEPARTMENTMENDATORY = true;
      this.departments = this.allDepartments;
      // this.departments.forEach((dept,index) =>{
      //   this.userAsHODList.forEach(user=>{
      //     if(user.asHODWithDepartment.id == dept.id){
      //       this.departments.splice(index,1);
      //     }
      //   })
      // })
    } else if (this.responsiblity.designation == "DIRECTOR") {
      this.IS_DEPARTMENTMENDATORY = false;
    } else if (this.responsiblity.designation == "VICE_PRINCIPAL") {
      this.IS_DEPARTMENTMENDATORY = false;
    } else if (this.responsiblity.designation == "TEACHER") {
      this.IS_DEPARTMENTMENDATORY = true;
    } else if (this.responsiblity.designation == "PHYSICAL_EDUCATION_SPORTS") {
      this.IS_DEPARTMENTMENDATORY = false;
      this.isDefaultDepartment = false;
      this.responsiblity.department = null;
    } else if (this.responsiblity.designation == "OFFICE_COORDINATOR") {
      this.IS_DEPARTMENTMENDATORY = false;
    } else if (
      this.responsiblity.designation == "COLLAGE_MGMT_REPRESENTATIVE"
    ) {
      this.IS_DEPARTMENTMENDATORY = false;
    } else if (this.responsiblity.designation == "LIBRARIAN") {
      this.IS_DEPARTMENTMENDATORY = false;
      this.isDefaultDepartment = false;
      this.responsiblity.department = null;
      /* this.departments.forEach((dept, index) => {
          if (dept?.name?.toUpperCase() == "LIBRARY") {
            this.responsiblity.departmentId = dept.id;
            this.responsiblity.department = dept;
            this.defaultDepartment = "Library";
            this.isDefaultDepartment = true;
            //this.departments.splice(index, 1);
          }
      }); */
    } else if (this.responsiblity.designation == "WEBSITE_COORDINATOR") {
      this.IS_DEPARTMENTMENDATORY = false;
      this.responsiblity.department = null;
    } else if (this.responsiblity.designation == "PRINCIPAL") {
      this.IS_DEPARTMENTMENDATORY = false;
    } else if (this.responsiblity.designation == "IQAC_ADMIN") {
      this.IS_DEPARTMENTMENDATORY = false;
    } else if (this.responsiblity.designation == "CRITERIA_COORDINATOR") {
      this.IS_CRITERIA_MANDATORY = true;
    } else {
      this.IS_DEPARTMENTMENDATORY = false;
      this.departments = this.allDepartments;
    }
    this.ADD_DEPARTMENT = true;
  }
  removeResponsiblity(userProfile, respoI) {
    // this.userProfile.responsiblities.splice(respoI,1);
    this.presentRemoveAlert(userProfile, respoI);
  }
  addResponsiblity() {
    this.ADD_DEPARTMENT = true;
    this.responsiblity.departmentId = undefined;
    this.responsiblity.designation = undefined;
    this.responsiblity.department = null;
  }
  onDepartmentChanged() {
    if (this.responsiblity.departmentId != null) {
      this.departments.forEach((dept) => {
        if (dept.id == this.responsiblity.departmentId) {
          this.responsiblity.department = dept;
        }
      });
    }
    this.userProfile.responsiblities.forEach((respo) => {
      if (
        respo.department?.id == this.responsiblity.departmentId &&
        respo.designation === this.responsiblity.designation
      ) {
        this.responsiblity.departmentId = null;
        this.responsiblity.department = null;
        this.userdataService.presentToast(
          "Same Responsibility for Same Designation cannot be applied.",
          "warning"
        );
      }
    });
  }
  onCriteriaChanged() {
    console.log(this.responsiblity.criteria);
    if (this.responsiblity.criteria != null) {
      this.userProfile.responsiblities.forEach((respo) => {
        if (
          respo.criteria == this.responsiblity.criteria &&
          respo.designation === this.responsiblity.designation
        ) {
          this.responsiblity.criteria = null;
          this.userdataService.presentToast(
            "Same Criteria cannot be applied.",
            "warning"
          );
        }
        return;
      });
    }
  }
  addResponsiblityInList() {
    // let responsiblityObj:any = {}
    // responsiblityObj.designation = this.responsiblity.designation;
    // responsiblityObj.designationLabel = this.responsiblity.designationLabel;
    // responsiblityObj.department = this.responsiblity.department;
    // this.userProfile.responsiblities.push(responsiblityObj)
    //console.log(this.userProfile)
    if (this.responsiblity.designation == "") {
      this.userdataService.presentToast("Please select a role", "warning");
      return;
    }
    if (this.responsiblity.designation == undefined) {
      this.userdataService.presentToast("Please select a role", "warning");
      return;
    }
    if (this.IS_DEPARTMENTMENDATORY && this.responsiblity.department == null) {
      this.userdataService.presentToast(
        "Please select a department",
        "warning"
      );
      return;
    }
    if (this.IS_CRITERIA_MANDATORY && this.responsiblity.criteria == "") {
      this.userdataService.presentToast("Please select a Criteria", "warning");
      return;
    }

    this.ADD_DEPARTMENT = false;
    this.presentAddAlert();
  }

  close() {
    this.modalController.dismiss(this.userProfile);
  }

  async updateProfile() {
    const loader = await this.loadingController.create({
      message: "Updating profile...",
    });
    loader.present();
    //console.log("Payload",this.userProfile);
    this.userdataService.getToken().then((token) => {
      if (token) {
        this.userProfileService
          .updateUserProfile(token, this.userProfile)
          .subscribe(
            (response) => {
              if (response) {
                this.userdataService.presentToast(
                  "Profile Updated successfully",
                  "success"
                );
                loader.dismiss();
                // this.close();
              }
            },
            (error) => {
              loader.dismiss();
            }
          );
      } else {
        loader.dismiss();
      }
    });
  }

  async presentRemoveAlert(userProfile, respoI) {
    let deptName =
      this.userProfile.responsiblities[respoI].department == null
        ? ""
        : " - " + this.userProfile.responsiblities[respoI].department.name;
    let subHeaderToShow =
      "Do you want to remove the role '" +
      this.userProfile.responsiblities[respoI].designationLabel +
      "" +
      deptName +
      "'?";
    const alert = await this.alertController.create({
      header: "Remove Role?",
      subHeader: subHeaderToShow,
      buttons: [
        {
          text: "Yes",
          role: "ok",
          handler: () => {
            this.updateProfile();
            this.userProfile.responsiblities.splice(respoI, 1);
            this.ngOnInit();
            this.responsiblity.designation = undefined;
            this.responsiblity.designation = null;
            this.responsiblity.designation = "";
            this.responsiblity.departmentId = undefined;
            this.responsiblity.departmentId = null;
            this.responsiblity.departmentId = "";
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("cancel clicked");
          },
        },
      ],
    });
    alert.present();
  }

  async presentAddAlert() {
    let deptName =
      this.responsiblity.department == null
        ? ""
        : " - " + this.responsiblity.department.name;
    let subHeaderToShow =
      "Do you want to add role  '" +
      this.responsiblity.designationLabel +
      deptName +
      "'?";
    if (
      this.responsiblity.designation == "CRITERIA_COORDINATOR" &&
      this.responsiblity.criteria == undefined
    ) {
      this.userdataService.presentToast("Please select a Criteria", "warning");
      return;
    }
    const alert = await this.alertController.create({
      header: "Add role",
      subHeader: subHeaderToShow,
      buttons: [
        {
          text: "Yes",
          role: "ok",
          handler: () => {
            this.updateProfile();
            let responsiblityObj: any = {};
            responsiblityObj.designation = this.responsiblity.designation;
            responsiblityObj.designationLabel =
              this.responsiblity.designationLabel;
            responsiblityObj.department = this.responsiblity.department;
            responsiblityObj.criteria = this.responsiblity.criteria;
            this.userProfile.responsiblities.push(responsiblityObj);

            this.ngOnInit();

            this.responsiblity.designation = undefined;
            // this.responsiblity.designation = null;
            // this.responsiblity.designation = "";
            this.responsiblity.departmentId = undefined;
            // this.responsiblity.departmentId = null;
            // this.responsiblity.departmentId = "";
            this.responsiblity.department = undefined;
            // this.responsiblity.department = null;
            // this.responsiblity.department = "";
            this.responsiblity.criteria = undefined;

            this.IS_DEPARTMENTMENDATORY = false;
            this.IS_CRITERIA_MANDATORY = false;
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("cancel clicked");
          },
        },
      ],
    });
    alert.present();
  }
}
