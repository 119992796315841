<!-- <ion-header>
  <ion-toolbar>
    <ion-title>termsAndConditionModel</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

</ion-content> -->
<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title style="text-align: center;">End User Licence Agreement </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModel()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <div>
          <p>
            PLEASE READ THIS END USER LICENCE AGREEMENT CAREFULLY before
            downloading, installing, accepting the terms and conditions, and/or
            using, the software/application E-AUDITING &amp; SYSTEMS INTEGRATION
            IN EDUCATION (EASIE) (the “Product” or the “App”). This End User
            Licence Agreement (“EULA”) governs your use of the “EASIE”
            software/application and its associated upgrades, patches, and
            updates and related services provided by SYNAPTIC SOLUTIONS (the
            “firm”). By agreeing to the terms &amp; conditions you will signify
            your acceptance of the following terms.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            This EULA sets out the basis on which SYNAPTIC SOLUTIONS makes the
            Product available to you (“User”) and on which You may use the
            Product, and constitutes a valid and binding legal agreement between
            SYNAPTIC SOLUTIONS and You for the use of the Product “EASIE”. By
            installing and/or using the Product, You agree to accept and to be
            bound by this EULA and the “Privacy Policy” herewith at all times.
            If You do not agree with any of these, please do not install or use
            the Product.
          </p>
        </div>
        <div>
          <div></div>
          <div>
            <p>
              SYNAPTIC SOLUTIONS reserves the right to change, modify, add or
              delete articles in this EULA at any time, in accordance with the
              procedures described below in Clause 10.
            </p>
          </div>
          <div></div>
          <div>
            <ol start="1">
              <li>
                <p>INTRODUCTION</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              SYNAPTIC SOLUTIONS has developed the online software/mobile
              application E-AUDITING &amp; SYSTEMS INTEGRATION IN EDUCATION
              (EASIE) (the “Product” or the “App”) for gathering information,
              notes, questionnaires, forms, electronic documentations, images
              and other information related to administration, course work,
              study program, gradation and events in educational institutions,
              universities, and academies for the purpose of approval,
              accreditation, certification, ranking, reference, and compilation
              of data for educational institutions, universities, and academies.
              SYNAPTIC SOLUTIONS through “EASIE” shall collect the afore-said
              data and may share it in a compiled format with assessment and/or
              accreditation government or non-government organisations such as
              “NAAC” for the purpose of accreditation/certification of the
              educational institutions, universities, and academies.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start="2">
              <li>
                <p>GRANT OF LICENSE</p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="1">
              <li>
                <p>
                  Pursuant to this EULA, SYNAPTIC SOLUTIONS through “EASIE”
                  shall grant You a non-exclusive, non-transferable,
                  non-sublicensed, non-commercial and personal license to
                  install and/or use the Product (in whole or in part) on the
                  device on which you download the App, for such time until
                  either You or SYNAPTIC SOLUTIONS terminates this EULA. You
                  must in no event use, nor allow others to use, the Product or
                  this License for commercial purposes without obtaining a
                  licence to do so from SYNAPTIC SOLUTIONS.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start="2">
              <li>
                <p>
                  You shall not, directly or indirectly (i) sell, rent out,
                  lease, license, distribute, market, exploit the Product or any
                  of its parts commercially; (ii) reverse engineer, decompile,
                  disassemble, adapt, reproduce, or create derivate works of
                  this Product, in whole or in part; (iii) create, use and/or
                  distribute “auto”, “trainer”, “script” or “macro” computer
                  programs or other “cheat” or “hack” programs or software
                  applications for this Product; (iv) remove, alter, disable or
                  circumvent any copyright and trademark indications or other
                  authorship and origin information, notices or labels contained
                  on or within this Product; and (v) export or re-export this
                  Product or any copy of adaptation in violation of any
                  applicable laws or regulations.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start="3">
              <li>
                <p>
                  While using the Product, You agree to comply with all
                  applicable laws, rules and regulations. You shall only use the
                  Product as anticipated, i.e. for providing, receiving,
                  approving and/or compiling information, notes, questionnaires,
                  forms, electronic documentations, images and other information
                  related to administration, course work, study program,
                  gradation and events in educational institutions,
                  universities, and academies.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start="4">
              <li>
                <p>
                  SYNAPTIC SOLUTIONS shall have the right to take action against
                  You, if You:
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="1">
              <li>
                <p>
                  create, use, share and/or publish by any means in relation to
                  the Product any material (text, words, images, sounds, videos,
                  etc.) which would be a breach of confidentiality, infringe any
                  intellectual property rights or an individual’s right to
                  privacy or which would incite the committing of an unlawful
                  act (in particular, piracy, cracking or circulation of
                  counterfeit software);
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start="2">
              <li>
                <p>
                  modify, distort, block, abnormally burden, disrupt, slow down
                  and/or hinder the normal functioning of all or any part of the
                  Product, or its accessibility to other users, or attempt to do
                  any of the above;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="3">
              <li>
                <p>
                  transmit or propagate any virus, trojan horse, worm, bomb,
                  corrupted file and/or similar destructive device or corrupted
                  data in relation to the Product, and/or organise, participate
                  in or be involved in any way in an attack on the servers of
                  SYNAPTIC SOLUTIONS and/or the Product and/or those of its
                  service providers;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="4">
              <li>
                <p>
                  create, supply or use alternative methods of using the
                  Products, for example server emulators;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="5">
              <li>
                <p>
                  post spamming chat, whether for personal or commercial
                  purposes;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="6">
              <li>
                <p>
                  transmit or communicate any material or content which, in the
                  sole and exclusive discretion of SYNAPTIC SOLUTIONS, is
                  believed or deemed to be offensive, including, but not limited
                  to, language that is harmful, threatening, unlawful, abusive,
                  harassing, defamatory, disparaging, obscene, sexually
                  explicit, or racially, ethnically, or otherwise objectionable;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start="7">
              <li>
                <p>harass or threaten other users of the Product; and</p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="8">
              <li>
                <p>
                  upload untruthful or false reports while using the Product.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start="3">
              <li>
                <p>OWNERSHIP</p>
              </li>
            </ol>
          </div>
          <div>
            <ol start="1">
              <li>
                <p>
                  All title, ownership rights and intellectual property rights
                  in and to the Product (including, without limitation, all
                  text, forms, graphics, music or sounds, all messages or items
                  of information, names, themes, objects, background,
                  representation, effects, diagrams, concepts, videos,
                  audio-visual effects, domain names and any other elements
                  which are part of the Product, individually or in combination)
                  and any and all copies thereof are owned by SYNAPTIC SOLUTIONS
                  or its licensors. The Product is protected by national and
                  international laws, copyright treaties and conventions and
                  other laws. This Product may contain certain licensed
                  materials and, in that event, licensors of SYNAPTIC SOLUTIONS
                  may protect their rights in the event of any violation of this
                  Agreement. Any reproduction or representation of these
                  licensed materials in any way and for any reason is prohibited
                  without SYNAPTIC SOLUTIONS prior permission and, if
                  applicable, permission of the licensors of SYNAPTIC SOLUTIONS.
                  SYNAPTIC SOLUTIONS reserves all rights not expressly granted
                  to you in this EULA.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start="2">
              <li>
                <p>
                  This License confers no title or ownership in the Product and
                  should not be construed as a sale of any rights in the
                  Product.
                  </p>
                  </li>
                  </ol>
                  </div>
                  <div></div>
                  <div>
                    <ol start="4">
                      <li>
                        <p>AUTHORIZED USERS</p>
                      </li>
                    </ol>
                  </div>
                  <div>
                    <ol start="1">
                      <li>
                        <p>
                          You shall create an account with unique credentials and strong
                          password for accessing the Product “EASIE”. On creating the
                          profile, you acknowledge and agree that the profile
                          information and password will remain confidential to you and
                          that you will not share the profile information or the
                          password with any other individual or entity. And that,
                          SYNAPTIC SOLUTIONS cannot be held liable for any loss of data
                          or compromise to your account caused by sharing of any
                          confidential information by you with other individual or
                          entity.
                        </p>
                      </li>
                    </ol>
                  </div>
                  </div>
                  <div>
                    <div></div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>
                            Any third party may not access your account under a remote
                            computing service, timesharing, facilities management,
                            outsourcing, or other such third-party access arrangements.
                            You shall not distribute the content on the portal to any
                            third party in modified or unmodified form through any form of
                            distribution such as digital or in print, with or without
                            costs.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            You may download/upload content on the portal; however, use of
                            the Product shall be restricted to you strictly for your
                            personal use or for the use of the
                            institution/university/academy. Commercial use of the content
                            available on the portal shall be strictly restricted. Any
                            other uses of the content will require explicit permission
                            from SYNAPTIC SOLUTIONS.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <ol start="5">
                        <li>
                          <p>ACCESS TO THE PRODUCT</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="1">
                        <li>
                          <p>
                            The Product can be installed on a phone, smartphone or tablet
                            having access to an electronic communication network for
                            connecting to the Internet. You acknowledge that the quality
                            of the Product, the response time or access to certain
                            features may depend on the capacities of your device and
                            strength of the electronic communication network. You
                            acknowledge that the Product may not work alike on all mobile
                            devices or through all network service providers. SYNAPTIC
                            SOLUTIONS shall in no way be held responsible for reduced user
                            comfort.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>
                            In order to effectively use the Product, you may be required
                            to provide certain personal data. SYNAPTIC SOLUTIONS may
                            collect and store data about You in relation to Your use of
                            the Product, Your connection information and/or Your Mobile
                            Device. Certain data may be recorded, archived, analysed and
                            used to create user statistics or for further processing. Your
                            privacy is very important to SYNAPTIC SOLUTIONS and SYNAPTIC
                            SOLUTIONS shall not reveal your personal data to third parties
                            except when needed for the purpose of working of the Product
                            as agreed by you by installing the App or when expressly
                            authorised by you to do so. SYNAPTIC SOLUTIONS may be under a
                            duty to disclose or share your personal data in order to
                            comply with a legal obligation, or in order to protect
                            SYNAPTIC SOLUTIONS rights and those of other users, licensors
                            and licensees. SYNAPTIC SOLUTIONS shall reserve the right to
                            collect, store and use specific and anonymous data about your
                            activity on the App. For further information concerning use of
                            your personal data, please read the Privacy Policy described
                            below in Clause 6.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            SYNAPTIC SOLUTIONS may use analytical tools to collect
                            information concerning your use of the Product. The analytical
                            tools may use server log files, web beacons, cookies, tracking
                            pixels and other technologies to collect said information.
                            Standing alone, this information is not “personal data” under
                            the Privacy Policy of SYNAPTIC SOLUTIONS.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="4">
                        <li>
                          <p>
                            The Product may display general information obtained from
                            third parties for explaining the criteria, metrics, marking
                            system, documentation requirement, etc., such as information
                            from recognized assessment and/or accreditation government or
                            non-government organisations such as NAAC, UGC. Such
                            information regarding, for example, NAAC accreditation, UGC
                            guidelines, and other such rules and regulations are freely
                            available on the respective websites of these organizations.
                            SYNAPTIC SOLUTIONS in no way claims any ownership or authority
                            over such information obtained from third parties, and only
                            presents the information on an “as available” basis
                            free-of-cost to the users. SYNAPTIC SOLUTIONS in no way
                            guarantees accuracy of such information, nor is it obligatory
                            for them to expeditiously update or remove such information
                            upon it being altered, changed, modified or removed by the
                            third party.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <ol start="6">
                        <li>
                          <p>PRIVACY POLICY</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="1">
                        <li>
                          <p>
                            “Personal Data” (or “Data”), is all information that
                            identifies you either directly, for example, your last name,
                            first name, email address or phone number; or indirectly, for
                            example, your account ID, profile name, IP address or cookie
                            identifier.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>We collect your personal data:</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="1">
                        <li>
                          <p>
                            when you create/register an EASIE account we collect your
                            name, profile name, password, profile image, email address,
                            phone number, position, employment details and address. Some
                            items of the data, such as your name/profile name and profile
                            image, testimonials, are made public to allow your community
                            to identify you at the portal. User will receive a
                            system-generated call, sms or email after certain actions are
                            performed for verification purpose.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>
                            when you upload any digital or non-digital content on the
                            portal including comments, images, documents, papers, study
                            material, research material, videos/video links, and weblinks
                            which you may upload on the App and/or share with other users
                            on the App.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            when you choose your settings, communication preferences,
                            verify your EASIE account, contact SYNAPTIC SOLUTIONS through
                            the App for support, or send any correspondence to the
                            institution/university/academy via the portal.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            We automatically collect certain data from you when you use
                            the portal, including internet or other network activity
                            information such as your IP address, geolocation-related data,
                            unique device identiﬁers, browsing and search history
                            (including content you viewed on the portal), operating system
                            and cookies.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <ol start="4">
                        <li>
                          <p>
                            We obtain permissions to access your device microphone or
                            camera while recording any audio/video using the portal. We
                            shall send/share the recorded audio/video to other user(s)
                            selected by you upon obtaining your express authorisation.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="5">
                        <li>
                          <p>
                            We collect data regarding your use of the portal and the
                            content that you may generate through and share on the portal.
                            We link your subscriber information with your activity on the
                            portal across all your devices using same EASIE account,
                            which, in case of paid subscriptions, is used for generating
                            bills/invoices.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="6">
                        <li>
                          <p>
                            We use your data to fulﬁl and enforce our commitment to
                            improve and administer the portal, and to allow you to use its
                            functionalities seamlessly. The data we collect is used for
                            purposes, including, but not limited to,
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div>
                      <ol start="1">
                        <li>
                          <p>improving, upgrading and/or developing the Product;</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>
                            detecting abuse, fraud, and illegal activity on the portal;
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            generating various reports which can be downloaded by you or
                            your institution/university/academy through their authorized
                            users or automatically emailed to you or the authorized users
                            of your institution/university/academy;
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="4">
                        <li>
                          <p>
                            generating various reports to be sent via email or paper
                            documents to assessment and/or accreditation government or
                            non-government organisations which are opted by the
                            institution/university/academy;
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="5">
                        <li>
                          <p>
                            enabling downloading of images, documents and reports by other
                            users in the portal, particularly tagged users, academic
                            auditors and admins;
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div>
                      <ol start="6">
                        <li>
                          <p>
                            generating statistical data with respect to use,
                            functionality, preference or progress of the Product;
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="7">
                        <li>
                          <p>
                            providing information to judicial authorities for the purpose
                            of an investigation, or in compliance with applicable law.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="7">
                        <li>
                          <p>
                            We shall not be liable for any data including, but not limited
                            to, names, profiles, documents, videos, audios, links and
                            other information, extracted and transferred by a user outside
                            the App and/or used or misused for unwarranted purposes in
                            violation of this EULA.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <ol start="8">
                        <li>
                          <p>
                            We shall not be liable for any data being rejected or held
                            invalid by the assessment and/or accreditation government or
                            non-government organisations, and it is the sole
                            responsibility of the user to provide accurate data in correct
                            format at all times.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="9">
                        <li>
                          <p>
                            We will maintain your data for a period of 5 years, after
                            which the data may be deleted if not in use by you.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="7">
                        <li>
                          <p>LIMITED WARRANTY</p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div>
                      <ol start="1">
                        <li>
                          <p>
                            SYNAPTIC SOLUTIONS warrants it has the right to license the
                            App “EASIE” to the user.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>
                            All the content on the portal is made available on an "as is"
                            basis without guarantee. SYNAPTIC SOLUTIONS and its licensors,
                            licensees and service providers do not make and hereby
                            disclaim any guarantees, conditions, warranties of any kind,
                            express, implied or statutory or otherwise, including: (a) its
                            conformity, accuracy, correctness, completeness, reliability
                            or authenticity; (b) its suitability for a particular use; (c)
                            implied warranties of title, non-infringement; (d) its market
                            value; or (e) your satisfaction. Without limiting the
                            generality of the foregoing, SYNAPTIC SOLUTIONS does not
                            warrant or represent that the content on the portal will
                            result in compliance, fulfilment or conformity with laws,
                            rules, regulations, requirements or guidelines of any
                            government or non-government agency unless expressly
                            specified.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            SYNAPTIC SOLUTIONS does not warrant that the App will be
                            uninterrupted or error-free, that defects will be corrected,
                            or that the App is free of viruses or other harmful
                            components. You shall assume all responsibility for
                            downloading the App to achieve your intended results, and for
                            the installation of, use of, and results obtained from the
                            App.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div></div>
                    <div>
                      <ol start="8">
                        <li>
                          <p>LIMITATIONS OF LIABILITY</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <ol start="1">
                        <li>
                          <p>
                            To the fullest extent permissible under applicable law, in no
                            event will SYNAPTIC SOLUTIONS, and its licensors, licensees,
                            affiliates, directors, employees, agents, service providers,
                            be liable for any loss or damage suffered in connection with
                            the use of the Product. This includes without limitation (a)
                            all losses of any kind, whether in tort (including for
                            negligence or breach of statutory duty), contract,
                            misrepresentation (whether innocent or negligent) or
                            otherwise, (b) direct loss; (c) accidental loss, (d)
                            incidental loss, (e) consequential loss, and (f) indirect
                            loss.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="2">
                        <li>
                          <p>
                            SYNAPTIC SOLUTIONS and its licensors disclaim any
                            responsibility or liability arising from the outcomes or the
                            use of the Product.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div></div>
                    <div>
                      <ol start="3">
                        <li>
                          <p>
                            Notwithstanding the afore-mentioned limitations of liability,
                            your sole remedy in the event of a dispute with SYNAPTIC
                            SOLUTIONS or its licensors, licensees and service providers is
                            to cease to use the Product; and if applicable, seek damages
                            for your losses. In no event SYNAPTIC SOLUTIONS, its
                            licensors, licensees and service providers be liable for
                            damages in excess of any amount you have paid to SYNAPTIC
                            SOLUTIONS for the Product during the twelve (12) months
                            immediately prior to the time your cause of action arose.
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div></div>
                    <div>
                      <ol start="9">
                        <li>
                          <p>INDEMNIFICATION</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <p>
                        You are solely responsible for any damage caused to SYNAPTIC
                        SOLUTIONS, its licensors, licensees, affiliates, directors,
                        employees, agents, service providers, and other users of the
                        Product or any other individual or legal entity as a result of
                        your violation of this EULA.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>
                        You hereby agree to defend, indemnify and keep indemnified
                        SYNAPTIC SOLUTIONS and its licensors, licensees, affiliates,
                        directors, employees, agents and service providers against any
                        claim or alleged claims, liabilities, losses, damages and all
                        costs (including lawyers’ fees), directly or indirectly
                        attributable to your fault and/or resulting from (a) a violation
                        of any provision of this EULA, or (b) your use or misuse of the
                        Product.
                      </p>
                    </div>
                    <div>
                      <p>
                        You also agree to indemnify and keep indemnified SYNAPTIC
                        SOLUTIONS for any loss of data or disruption in service/use of the
                        Product, due to technical glitch at third-party servers, and in
                        situation of Force Majeure event.
                      </p>
                    </div>
                    <div></div>
                    <div>
                      <ol start="10">
                        <li>
                          <p>NOTIFICATION OF CHANGES</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <p>
                        SYNAPTIC SOLUTIONS reserves the right, in its sole and absolute
                        discretion, to revise, update, change, modify, add to, supplement,
                        or delete certain terms of this EULA for security, legal, best
                        practice or regulatory reasons. Such changes will be effective
                        with or, as applicable, without prior notice to You. You can
                        review the most current version of this EULA by clicking on the
                        “EULA” link in the Product. You are responsible for checking this
                        EULA periodically for changes. If any future changes to this EULA
                        are unacceptable to You or cause You to no longer be in agreement
                        or compliance with this EULA, You may terminate this EULA in
                        accordance with Section 8 and must immediately uninstall the
                        Product and destroy all copies of the Product. Your continued use
                        of the Product following any revision to this EULA constitutes
                        Your complete and irrevocable acceptance of any and all such
                        changes.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div></div>
                    <div>
                      <p>
                        SYNAPTIC SOLUTIONS may modify the Product for any reason or
                        without any specific reason, at any time and at its entire
                        discretion, in particular for technical reasons such as updates,
                        maintenance operations and/or resets to improve and/or optimize
                        the Product. You agree that the Product may install or download
                        the modifications automatically. You agree that SYNAPTIC SOLUTIONS
                        may stop to support previous versions of the Product upon
                        availability of an updated version.
                      </p>
                    </div>
                    <div></div>
                    <div>
                      <ol start="11">
                        <li>
                          <p>TERMINATION</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <p>
                        The EULA is effective from the date you license, download or use
                        the Product, until terminated according to its terms. You and
                        SYNAPTIC SOLUTIONS (or its licensors) may terminate this EULA, at
                        any time, for any reason. Termination by SYNAPTIC SOLUTIONS will
                        be effective upon (a) notice to You; or (b) termination of Your
                        EASIE Account; or (c) discontinuation of offering and/or
                        supporting the Product by SYNAPTIC SOLUTIONS. This EULA may
                        terminate automatically if You fail to comply with any of the
                        terms and conditions of this EULA. Upon termination for any
                        reason, you must immediately uninstall the Product and destroy all
                        copies of the Product in Your possession.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div></div>
                    <div>
                      <ol start="12">
                        <li>
                          <p>GOVERNING LAW AND SEVERABILITY</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <p>
                        To the extent permitted by applicable law, this EULA and any
                        disputes or claims arising out of or in connection with it or its
                        subject matter or formation (including non-contractual disputes or
                        claims) are governed by and construed in accordance with the laws
                        of India. You irrevocably agree that the courts of India have
                        exclusive jurisdiction to settle any dispute or claim that arises
                        out of or in connection with the EULA or its subject matter or
                        formation (including non-contractual disputes or claims).
                      </p>
                    </div>
                    <div>
                      <p>
                        If any court of competent jurisdiction or competent authority
                        finds that any provision of this EULA is invalid, illegal or
                        unenforceable, that provision or part-provision shall, to the
                        extent required, be deemed to be deleted, and the validity and
                        enforceability of the other provisions of this EULA shall not be
                        affected.
                      </p>
                    </div>
                    <div></div>
                  </div>
                  <div>
                    <div>
                      <ol start="13">
                        <li>
                          <p>NO WAIVER</p>
                        </li>
                      </ol>
                    </div>
                    <div>
                      <p>
                        No failure or delay by SYNAPTIC SOLUTIONS (or its licensors,
                        licensees) to exercise any right or remedy provided under this
                        EULA or by law shall constitute a waiver of that or any other
                        right or remedy, nor shall it preclude or restrict the further
                        exercise of that or any other right or remedy. No single or
                        partial exercise of such right or remedy shall preclude or
                        restrict the further exercise of that or any other right or
                        remedy.
                      </p>
          </div>
          <div></div>
          <div>
            <ol start="14">
              <li>
                <p>NOTICES</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
              Any notice or communication hereunder shall be in writing and
              either delivered to the registered office address by a recognized
              express delivery courier or sent through email on registered email
              address of SYNAPTIC SOLUTIONS. All notices shall be in English,
              and effective upon receipt.
              </p>
          </div>
        </div>
        <div></div>
        <div>
          <p>
            For any questions concerning this EULA, you may contact SYNAPTIC
            SOLUTIONS at the following email address:
            <a href="mailto:support@easie.in" target="_blank" rel="noreferrer noopener">
              support@easie.in
            </a>
            </p>
        </div>
      </ion-col>
    </ion-row>
    </ion-grid>
</ion-content>
