export class Constants {

    /** ------ Loading Messages for Form Actions --------- */
    public static SENDING_FOR_APPROVAL = 'Sending Form for Approval...';
    public static REVERTING_TO_WEB_CO = 'Forwarding ...';
    public static REVERTING_TO_OFFICE_CO = 'Forwarding to Office-Co ...';
    public static REVERTING_TO_OFFICE_CO_AND_WEB_CO = 'Forwarding to Office-Co & Web-Co ...';
    public static REVERTING_TO_STAFF = 'Reverting to Owner...';
    public static GETTING_FORM_DETAILS = 'Getting Form Details ...';
    public static CREATING_FORM = 'Creating Form...';
    public static UPDATING_FORM = 'Updating Form...';
    public static UPDATING_FORM_SHARING = 'Updating Form Sharing...';
    public static DELETING_FORM = 'Deleting Form...';
    public static APPROVING_FORM = 'Approving Form...';
    public static RE_SENDING_FORM = 'Returning Form...';
    public static RE_CALLING_FORM = 'Re-Calling Form...';
    public static GETTING_INFO = 'Getting Info ...';
    public static FINISHING_FORM_SUBMISSION = 'Finishing Form Submission...';
    public static FINISHING_FORM_REVERTING = 'Finishing Form Forwarding...';    
    public static UPDATING_FORM_WEB_CO = 'Updating web-link...';


    /** ------ Loading Messages for Form Actions End --------- */


    public static LastNameComparator = function(x, y) {
        let a = x.lastName.toUpperCase(),
        b = y.lastName.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
    }

    public static YearFromComparator = function(x, y) {
        let a = x.yearFrom,
        b = y.yearFrom;
        return a == b ? 0 : a < b ? 1 : -1;
    }

    public static IS_APPROVED = function isApproved(form:any):boolean{
        let approved = false;
        approved = (form.status == "APPROVED" ||
        form.status == "SHARED_WITH_WebC" ||
        form.status == "SHARED_WITH_OFFICE_CO" ||
        form.status == "SHARED_WITH_WEB_AND_OFFICE" ||
        form.status == "APPROVED_WITH_OFFICE_CO" ||
        form.status == "APPROVED_WITH_WEB_AND_OFFICE" ||
        form.status == "APPROVED_WITH_WebC_UPDATED" ||
        form.status ==
          "APPROVED_WITH_OFFICE_CO_UPDATED" ||
        form.status ==
          "APPROVED_WITH_WEB_AND_OFFICE_UPDATED" ||
        form.status ==
          "APPROVED_WITH_BOTH_OFFICE_UPDATED" ||
        form.status ==
          "APPROVED_WITH_BOTH_WebC_UPDATED" ||
        form.status == "APPROVED_WITH_WebC")
        return approved;
    }
}
