<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-primary text-center" role="alert">
        <i class="fas fa-building"></i> &nbsp; COLLEGE TYPE-YEAR MAPPING
      </div>
    </div>
    <div class="col-12">
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <i class="fas fa-info-circle"></i>&nbsp; College type once assigned to a year cannot be changed.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-2"></div>
    <div class="col-12 col-md-8">
      <div class="row easie-hori-row p-1">
        <div class="col-6 col-md-3 mb-2">
          <div class="dropdown d-flex flex-row-reverse">
            <a class="btn btn-block btn-outline-primary dropdown-toggle notch-left" href="#" role="button"
              id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>{{selectedYearFrom ? selectedYearFrom : 'From'}}</span>
            </a>
            <div class="dropdown-menu year-selection-dropdown" aria-labelledby="dropdownMenuLink" style="width: 100%;">
              <a class="dropdown-item" *ngFor="let fromYear of fromYearOptions"
                (click)="handleFromYearSelection(fromYear)">{{fromYear}}</a>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3 mb-2">
          <button type="button" *ngIf="selectedYearTo"
            class="btn btn-block btn-outline-primary notch-right">{{selectedYearTo}}</button>
          <button type="button" *ngIf="!selectedYearTo" class="btn btn-block btn-outline-warning notch-right">Select
            From Year</button>

        </div>
        <div class="col-12 col-md-4 mb-2">
          <!-- Example single danger button -->
          <div class="dropdown d-flex flex-row-reverse">
            <a class="btn btn-block btn-outline-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span>{{selectedCollegeType ? selectedCollegeType : 'College type'}}</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="width: 100%;">
              <a class="dropdown-item" *ngFor="let type of metadata.collegeTypeList"
                (click)="handleCollegeTypeChange(type)">{{type}}</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 mb-2">
          <button type="button" [disabled]="!selectedYearFrom || !selectedCollegeType" class="btn btn-block btn-primary"
            (click)="confirmationAlertForMappingAddition()">
            Add
            &nbsp;<i class="fas fa-plus-circle fa-lg"></i></button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2"></div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4"></div>
    <div class="col-12 col-md-4">
      <div *ngFor="let mapping of yearToCollegeMapping" class="easie-hori-row">
        <button type="button" class="btn btn-light">{{mapping.yearFrom}}</button>
        <div class="year-mapping-separator"></div>
        <button type="button" class="btn btn-outline-primary"
          style="border-radius: 10px;">{{mapping.collegeType}}</button>

        <div class="year-mapping-separator"></div>
        <button type="button" class="btn btn-light">{{mapping.yearTo}}</button>
      </div>
      <div class="col-12 col-md-4"></div>
    </div>
    <div class="col-12 col-md-4"></div>
  </div>
</div>