import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ToastController } from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { ToastService } from "./toast-service";
@Injectable({
  providedIn: "root",
})
export class UserDataService {
  public static HAS_LOGGED_IN = "hasLoggedIn";
  public static SECURITY_TOKEN = "SECURITY_TOKEN";
  public static USER_NAME = "USER_NAME";
  public static USER_EMAIL_ID = "USER_EMAIL_ID";
  public static METADATA: string = "METADATA";
  public static USER_PROFILE: string = "USER_PROFILE";
  public static SHARED_FORM: string = "SHARED_LITE_FORM_DTO";
  public static COLLEGE_STAFF: string = "COLLAGE_STAFF";
  public static DEPARTMENT_FOR_HOD_FORM: string = "SELECTED_DEPARTMENT_MENU";
  public static NACC_METRIC_DATA: string = "NACC_METRIC_DATA";
  public static ACTIVE_SECTION: string = "ACTIVE_SECTION";
  public static SKIP_UPDATE: string = "SKIP_UPDATE";
  public static PROFILE_COMPLETION: string = "PROFILE_COMPLETION";
  public static CURRENT_CRITERIA: string = "CURRENT_CRITERIA";
  constructor(
    public toastController: ToastController,
    private toastService: ToastService,
    public storage: Storage,
    public http: HttpClient
  ) {}

  /** ------------ All About Toast Messages ----------------- */
  async presentToast(messageToShow, colorToUse) {
    // const toast = await this.toastController.create({
    //   message: messageToShow,
    //   duration: 4000,
    //   position: 'top',
    //   animated: true,
    //   color: colorToUse,
    //   mode: "ios",
    //   cssClass: "topPadding",
    //   buttons: [
    //     {
    //       text: 'Close',
    //       role: 'cancel',
    //       handler: () => {

    //       }
    //     }
    //   ]
    // });
    // toast.present();
    this.toastService.show(messageToShow, colorToUse, 3000);
  }

  showUnAuthorisedToast() {
    this.presentToast(
      "Your are not Authorized to use this Application!",
      "warning"
    );
  }

  showFormApprovedToast() {
    this.presentToast("Form approved successfully!", "success");
  }

  showMissingInputToast() {
    this.presentToast("Please Enter all Required Fields!", "warning");
  }

  showIncorrectDataInputToast() {
    this.presentToast("Incorrect data Provided!", "warning");
  }

  showToastFormCreateSuccess() {
    this.presentToast("Form Created Successfully", "success");
  }

  showToastFormCreateFailed() {
    this.presentToast("Form Creation Failed", "danger");
  }
  showToastFormUpdateSuccess() {
    this.presentToast("Form Updated Successfully", "success");
  }

  showToastFormUpdateFailed() {
    this.presentToast("Form Update Failed", "danger");
  }

  showToastFormSharedSuccess() {
    this.presentToast("Form Shared Successfully", "success");
  }

  showToastFormSharedFailed() {
    this.presentToast("Form Sharing Failed", "danger");
  }

  showToastFormRecallSuccess() {
    this.presentToast("Form Recall Successfully", "success");
  }

  showToastFormRevertedToWebCoSuccess() {
    this.presentToast(
      "Form Forwarded to Web Co-ordinator Successfully",
      "success"
    );
  }

  showToastFormRevertedToOfficeCoSuccess() {
    this.presentToast(
      "Form Forwarded to Office Co-ordinator Successfully",
      "success"
    );
  }

  showToastFormRevertedToOfficeAndWebCoSuccess() {
    this.presentToast(
      "Form Forwarded to Office & Web Co-ordinator Successfully",
      "success"
    );
  }
  showToastFormRevertedToOfficeCoFailed() {
    this.presentToast(
      "Form Forwarding to Office Co-ordinator Failed",
      "danger"
    );
  }
  showToastFormRevertedToWebCoFailed() {
    this.presentToast("Form Forwarding to Web Co-ordinator Failed", "danger");
  }

  showToastFormRevertedToOfficeAndWebCoFailed() {
    this.presentToast(
      "Form Forwarding to Office & Web Co-ordinator Failed",
      "danger"
    );
  }

  showToastFormSubmittedForApprovalSuccess() {
    this.presentToast("Form Submitted for Approval Successfully", "success");
  }

  showToastFormSubmittedForApprovalFailed() {
    this.presentToast("Form Submission for Approval Failed", "danger");
  }

  showToastFormDeletionSuccess() {
    this.presentToast("Form Deleted Successfully", "success");
  }

  showToastFormRevertReasonMissing() {
    this.presentToast("Please add Message.", "warning");
  }

  showToastFormRevertedSuccess() {
    this.presentToast("Form Reverted Successfully.", "success");
  }

  showToastFormReturnSuccess() {
    this.presentToast("Form Return Successfully.", "success");
  }

  showCommingSoonFeatureToast() {
    this.presentToast(
      "Hold back, we are stretching out to provide this feature soon!",
      "dark"
    );
  }
  /** ------------ All About Toast Messages End ----------------- */
  saveDepartment(department: any): Promise<any> {
    return this.storage.set(
      UserDataService.DEPARTMENT_FOR_HOD_FORM,
      department
    );
  }
  getDepartment() {
    return this.storage.get(UserDataService.DEPARTMENT_FOR_HOD_FORM);
  }
  removeDepartment() {
    return this.storage.remove(UserDataService.DEPARTMENT_FOR_HOD_FORM);
  }
  saveSecurityToken(securityToken: any): Promise<any> {
    return this.storage.set(UserDataService.SECURITY_TOKEN, securityToken);
  }
  saveSharedLiteForm(sharedForm: any): Promise<any> {
    return this.storage.set(UserDataService.SHARED_FORM, sharedForm);
  }
  getStoredLiteForm(): Promise<any> {
    return this.storage.get(UserDataService.SHARED_FORM);
  }

  saveStaffForCollege(collegeStaff: any): Promise<any> {
    return this.storage.set(UserDataService.COLLEGE_STAFF, collegeStaff);
  }
  getStoreStaff(): Promise<any> {
    return this.storage.get(UserDataService.COLLEGE_STAFF);
  }
  removeStoredForm(): Promise<any> {
    return this.storage.remove(UserDataService.SHARED_FORM);
  }

  async getToken() {
    const tokenObject = await this.storage.get(UserDataService.SECURITY_TOKEN);
    if (tokenObject) {
      return tokenObject.token;
    } else {
      return;
    }
  }
  async getLoggedInUserRole() {
    const tokenObject = await this.storage.get(UserDataService.SECURITY_TOKEN);
    if (tokenObject) {
      return tokenObject.roles;
    } else {
      return;
    }
  }

  async getApplicationUserFromSecurityTokenResult() {
    const tokenObject = await this.storage.get(UserDataService.SECURITY_TOKEN);
    if (tokenObject) {
      return tokenObject.applicationUser;
    } else {
      return;
    }
  }

  async isLoggedIn() {
    const loggedIn = await this.storage.get(UserDataService.HAS_LOGGED_IN);
    return loggedIn;
  }

  logout(): Promise<any> {
    window.dispatchEvent(new CustomEvent("user:logout"));
    window.dispatchEvent(new CustomEvent("inbox:logout"));
    return this.storage
      .remove(UserDataService.HAS_LOGGED_IN)
      .then(() => {
        this.storage.remove(UserDataService.USER_NAME);
        this.storage.remove(UserDataService.USER_PROFILE);
        this.storage.remove(UserDataService.COLLEGE_STAFF);
        this.storage.remove(UserDataService.SHARED_FORM);
        return this.storage.remove(UserDataService.SECURITY_TOKEN);
      })
      .then(() => {
        // this.events.publish('user:logout');
      });
  }
  saveUserProfile(userprofile: any): Promise<any> {
    window.dispatchEvent(new CustomEvent("user:profile-available"));
    return this.storage.set(UserDataService.USER_PROFILE, userprofile);
  }

  async getUserProfile(): Promise<any> {
    return await this.storage.get(UserDataService.USER_PROFILE);
  }
  saveCurentSection(section: any): Promise<any> {
    return this.storage.set(UserDataService.ACTIVE_SECTION, section);
  }

  saveCurrentCriteria(criteria: any): Promise<any> {
    return this.storage.set(UserDataService.CURRENT_CRITERIA, criteria);
  }

  getCurrentCriteria(): Promise<any> {
    return this.storage.get(UserDataService.CURRENT_CRITERIA);
  }
  removeSavedCurrentSection() {
    return this.storage.remove(UserDataService.ACTIVE_SECTION);
  }

  async getCurrentActiveSection(): Promise<any> {
    return await this.storage.get(UserDataService.ACTIVE_SECTION);
  }

  login(username: string) {
    this.setUsername(username);
    this.storage.set(UserDataService.HAS_LOGGED_IN, true);
    window.dispatchEvent(new CustomEvent("user:login"));
    window.dispatchEvent(new CustomEvent("inbox:login"));
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set(UserDataService.USER_NAME, username);
  }

  getUsername(): Promise<any> {
    return this.storage.get(UserDataService.USER_NAME);
  }
  saveEmailId(emailId: string): Promise<any> {
    this.storage.set(UserDataService.HAS_LOGGED_IN, true);
    return this.storage.set(UserDataService.USER_EMAIL_ID, emailId);
  }
  getEmailId(): Promise<any> {
    return this.storage.get(UserDataService.USER_EMAIL_ID);
  }
  saveMetadata(metadata): Promise<any> {
    return this.storage.set(UserDataService.METADATA, metadata);
  }

  getMetadata(): Promise<any> {
    return this.storage.get(UserDataService.METADATA);
  }

  saveProfileCompletiondata(profileCompletion): Promise<any> {
    return this.storage.set(
      UserDataService.PROFILE_COMPLETION,
      profileCompletion
    );
  }

  getProfileCompletiondata(): Promise<any> {
    return this.storage.get(UserDataService.PROFILE_COMPLETION);
  }

  saveMetricdata(metricData): Promise<any> {
    return this.storage.set(UserDataService.NACC_METRIC_DATA, metricData);
  }

  getMetricdata(): Promise<any> {
    return this.storage.get(UserDataService.NACC_METRIC_DATA);
  }
  /* readNaccMatricDataMapping(): Observable<any> {
    return this.http.get('assets/data/naacmetric.json');
  } */

  setIsSkipUpdate(status: boolean): Promise<any> {
    return this.storage.set(UserDataService.SKIP_UPDATE, status);
  }

  getIsSkipUpdate(): Promise<any> {
    return this.storage.get(UserDataService.SKIP_UPDATE);
  }
}
