import { UserProfileService } from "src/app/providers/userProfile/user-profile.service";
import { EsupportMappingService } from "src/app/providers/esupport/esupport-mapping.service";
import { Component, OnInit } from "@angular/core";

import {
  Platform,
  AlertController,
  NavController,
  ModalController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { UserDataService } from "./providers/userData/user-data.service";
import { MetadataService } from "./providers/metadata/metadata.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormsSharingService } from "./providers/forms/formsSharing/forms-sharing.service";
import * as _ from "lodash";

declare var $: any;

// PUSH
// import { FCM } from "@ionic-native/fcm/ngx";
// import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from "src/environments/environment";
import { DashboardService } from "./providers/dashboard/dashboard.service";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market/ngx";
import { Storage } from "@ionic/storage";
import { YearManagementPage } from "./pages/year-management/year-management.page";
import { YearCollegeTypeMappingService } from "./providers/yearCollegeMapping/year-college-type-mapping.service";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: environment.firebaseConfig.apiKey,
  authDomain: environment.firebaseConfig.authDomain,
  projectId: environment.firebaseConfig.projectId,
  storageBucket: environment.firebaseConfig.storageBucket,
  messagingSenderId: environment.firebaseConfig.messagingSenderId,
  appId: environment.firebaseConfig.appId,
  measurementId: environment.firebaseConfig.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  app_version: string;
  isBetaVersion: boolean = false;
  isBlackListedVersion: boolean = false;
  isLoggedIn: boolean = false;
  profile: any = {
    name: "",
  };
  superUserLogin: boolean = false;
  isIQACADMIN: boolean = false;
  isIQACCOADMIN: boolean = false;
  isIQACCOADMINOnly: boolean = false;
  subscriptionStatus: string;
  webc: boolean = false;
  public selectedIndex = 0;
  uuid: any;
  personalSectionForms = [];
  departmentSectionForms = [];
  iqacSectionForms = [];
  officeCoSectionForms = [];
  librarySectionForms = [];
  greenInitiativesSectionForms = [];
  physicalEducationSportsSectionForms = [];

  iqacSectionAllowed = false;
  collegeAdminAllowed = false;
  onlyCollegeAdminAllowed = false;
  departmentSectionAllowed = false;
  personalSectionAllowed = false;
  officeCoSectionAllowed = false;
  librarySectionAllowed = false;
  greenInitiativesSectionAllowed = false;
  physicalEducationSportsSectionAllowed = false;
  isFacilitator: boolean = false;
  allotedDepartmentList = [];
  sharedFormCount: number = 0;
  isDesktop = false;
  isMobileApp = false;
  metadata: any = {};
  isExpired: boolean = true;
  quotaExhausted: boolean = true;
  os: any;
  skipStatus: boolean;
  response: any;
  collegeId: any;
  userProfile: any = {};
  profileCompletion: any;
  isProfileCompletionCheck: boolean = false;
  yearMappingLength: any;

  activeSection: any;
  currentCriteria: any;
  allowedCrietrias: any = [];
  criteriaOrdinatorAllowed: boolean = false;

  public appPages = [
    {
      title: "Onboarding",
      url: "/management-committee",
      icon: "far fa-plus-square",
    },
    {
      title: "Invitation",
      url: "/invitation",
      icon: "far fa-paper-plane",
    },
    // {
    //   title: 'Enquiry Management',
    //   url: 'enquiry-manangement',
    //   icon: 'server'
    // },
    {
      title: "Activation",
      url: "/activation",
      icon: "far fa-check-circle",
    },
    {
      title: "Demo Enquiry",
      url: "demo-enquiry",
      icon: "fas fa-headset",
    },
  ];

  public appPagesIQAC = [
    {
      title: "Invitation Management",
      url: "/invitation",
      icon: "fas fa-paper-plane",
    },
    {
      title: "Department Management",
      url: "/departmet-management",
      icon: "fas fa-building",
    },
    {
      title: "Committee Management",
      url: "/college-committte-mangement",
      icon: "fas fa-object-group",
    },
    {
      title: "Role Management",
      url: "/staff-management",
      icon: "fas fa-users",
    },
    {
      title: "College Type-Year Mapping",
      url: "/year-management",
      icon: "fas fa-landmark",
    },
    {
      title: "Download Reports",
      url: "/report",
      icon: "fas fa-file",
    },
  ];

  public appPagesIQACCoAdmin = [
    {
      title: "Download Reports",
      url: "/report",
      icon: "fas fa-file",
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public userDataService: UserDataService,
    private dashboardService: DashboardService,
    public metadataService: MetadataService,
    public alertController: AlertController,
    public router: Router,
    public route: ActivatedRoute,
    public formsSharingService: FormsSharingService,
    public navController: NavController,
    public esupportMappingService: EsupportMappingService,
    public userProfileService: UserProfileService,
    public userdataService: UserDataService,
    private appVersion: AppVersion,
    private market: Market,
    private storage: Storage,
    // private fcm: FCM,
    public plt: Platform,
    public modalController: ModalController,
    public collegeTypeYearMappingService: YearCollegeTypeMappingService
  ) {
    this.initializeApp();
    window["FreshworksWidget"]("hide");

    const launcherFrame = document.getElementById("launcher-frame");

    if (launcherFrame != null) {
      launcherFrame.style.maxWidth = "80px";
      launcherFrame.style.minWidth = "86px";
    }
    // this.plt.ready().then(() => {
    //   FCM?.onNotification().subscribe((data) => {
    //     if (data.wasTapped) {
    //       console.log("Received in background");
    //     } else {
    //       console.log("Received in foreground");
    //     }
    //   });

    //   FCM?.onTokenRefresh().subscribe((token) => {
    //     // Register your new token in your back-end if you want
    //     // backend.registerToken(token);
    //     console.log("registered token is.....", token);
    //     this.userDataService.getToken().then((userToken) => {
    //       this.userProfileService
    //         .getPushToken(userToken, token)
    //         .toPromise()
    //         .then(() => {})
    //         .catch(() => {});
    //     });
    //   });
    // });
    // setTimeout(() => {
    //   this.getToken();
    // }, 5000);
  }

  /* subscribeToTopic() {
    FCM?.subscribeToTopic("enappd");
  }
  getToken() {
    FCM?.getToken()
      .then(async (token) => {
        this.userDataService.getToken().then((userToken) => {
          this.userProfileService
            .getPushToken(userToken, token)
            .toPromise()
            .then(() => {})
            .catch(() => {});
        });
      })
      .catch(async (e) => {
        console.log("error getting  token", e);
      });
  }
  unsubscribeFromTopic() {
    FCM?.unsubscribeFromTopic("enappd");
  } */

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      this.betaVersionCheck();
      if (this.platform.is("desktop")) {
        this.isDesktop = true;
      }
      if (
        this.platform.is("android") ||
        this.platform.is("ios") ||
        this.platform.is("mobile") ||
        this.platform.is("iphone")
      ) {
        this.isMobileApp = true;
      }
    });

    $(document).ready(() => {
      $(document).click(() => {
        ($(".navbar-collapse") as any).collapse("hide");
      });
    });
  }

  getFormLiteInfoForLoggedInUser() {
    this.userDataService.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        this.userDataService.getStoredLiteForm().then((formList) => {
          if (formList) {
            this.sharedFormCount = formList.length;
            this.userDataService.saveSharedLiteForm(formList);
          }
        });
      }
    });
  }
  loadMetadata() {
    this.metadataService.getMetadata().subscribe((response) => {
      if (response) {
        this.metadata = response;
        this.segregateSectionWiseFormList();
        this.userDataService.saveMetadata(response);

        // this.betaVersionCheck();
      }
    });
    this.esupportMappingService
      .readESupportMetricMapping()
      .subscribe((mappings) => {
        this.userDataService.saveMetricdata(mappings);
      });
    /* this.userdataService.getToken().then((token) => {
      if (token) {
        
      }
    }); */
  }
  ngOnInit() {
    this.fetchuserProfile();
    this.loadMetadata();
    this.checkLoginStatus();
    this.listenForLoginEvents();
    this.checkwhichUserIsLogin();
    this.listenForLoginEventsForInbox();
    this.fetchUpdatedProfile();
    this.formQuota();
  }

  getProfileCompletionDetails() {
    this.userdataService.getToken().then((token) => {
      if (token) {
        this.userProfileService
          .getProfileCompletionDetails(token)
          .subscribe((response) => {
            this.userDataService.saveProfileCompletiondata(response);
            this.profileCompletion = response;
            if (
              0 < this.profileCompletion.commitee &&
              0 < this.profileCompletion.department &&
              0 < this.profileCompletion.staff &&
              0 < this.profileCompletion.yearMapping
            ) {
              this.isProfileCompletionCheck = true;
            }
          });
      }
    });
  }

  fetchAllCollegeTypeYearMappingsForCollegeId() {
    this.userdataService.getUserProfile().then((profile) => {
      this.collegeId = profile.college.id;
    });

    this.userdataService.getToken().then((token) => {
      this.collegeTypeYearMappingService
        .fetchCollegeTypeYearMapping(token, {
          collegeId: this.collegeId,
        })
        .subscribe(
          (response) => {
            this.yearMappingLength = response.mappings.length;

            if (
              this.yearMappingLength < 1 ||
              this.yearMappingLength == undefined
            ) {
              if (this.subscriptionStatus == "SUBS_ACTIVE") {
                this.openYearMappingModal();
              }
            }
          },
          (error) => {}
        );
    });
  }

  async openYearMappingModal() {
    const modal = await this.modalController.create({
      component: YearManagementPage,
      componentProps: {},
      backdropDismiss: false,
      cssClass: "modal-fullscreen",
    });
    modal.present();
  }

  async getCurrentAppVersion() {
    this.userDataService.setIsSkipUpdate(false);
    await this.appVersion.getVersionNumber().then(
      (versionNumber) => {
        this.app_version = versionNumber;
      },
      (error) => {
        console.log("error :: ", error);
      }
    );

    if (this.platform.is("android")) {
      this.os = "android";
    } else if (this.platform.is("ios")) {
      this.os = "ios";
    }
  }

  async betaVersionCheck() {
    await this.getCurrentAppVersion();
    if (this.app_version != undefined && this.os != undefined) {
      this.userDataService.getIsSkipUpdate().then((status) => {
        this.skipStatus = status;
      });

      this.userProfileService
        .getAppVersionStatusList(this.os, this.app_version)
        .subscribe(
          (response) => {
            response.forEach((versionStatus) => {
              if (versionStatus?.isBeta) {
                this.isBetaVersion = true;
              }

              if (versionStatus?.status == "BLACKLISTED") {
                this.blackListedVersionAlert();
              }
              if (versionStatus?.status == "SUPPORTED") {
                if (!this.skipStatus) {
                  this.supportedVersionAlert();
                }
              }
            });
          },
          (error: any) => {
            console.log("error is :", error);
          }
        );
    }
  }

  romanStringToInt(romanString) {
    const romanMap = {
      I: 1,
      II: 2,
      III: 3,
      IV: 4,
      V: 5,
      VI: 6,
      VII: 7,
    };

    return romanMap[romanString];
  }
  segregateSectionWiseFormList() {
    this.personalSectionForms = [];
    this.iqacSectionForms = [];
    this.departmentSectionForms = [];
    this.allotedDepartmentList = [];
    this.physicalEducationSportsSectionForms = [];
    this.greenInitiativesSectionForms = [];
    this.officeCoSectionForms = [];
    this.librarySectionForms = [];
    this.allowedCrietrias = [];

    this.iqacSectionAllowed = false;
    this.departmentSectionAllowed = false;
    this.personalSectionAllowed = false;
    this.officeCoSectionAllowed = false;
    this.librarySectionAllowed = false;
    this.greenInitiativesSectionAllowed = false;
    this.physicalEducationSportsSectionAllowed = false;

    this.userDataService.getLoggedInUserRole().then((roles) => {
      if (roles) {
        if (roles.includes("FACILITATOR")) {
          this.isFacilitator = true;
        } else {
          this.isFacilitator = false;
        }
        /* if (roles.includes("PRINCIPAL_EQUI") || roles.includes("COLL_MGMT")) {
          this.collegeAdminAllowed = true;
        } else {
          this.collegeAdminAllowed = false;
        } */
        this.metadata.formSections.forEach((section) => {
          let allowedDesignations = section.designationList.split(",");

          let sectionName = section.label;
          if (sectionName === "Department") {
            this.departmentSectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.departmentSectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.departmentSectionAllowed = true;
              }
            });
            if (this.departmentSectionAllowed) {
              if (this.profile.responsiblities)
                this.profile.responsiblities.forEach((responsiblity) => {
                  if (responsiblity) {
                    if (
                      responsiblity.designation == "HEAD_OF_DEPT" &&
                      responsiblity.department &&
                      !responsiblity.department.deleted
                    ) {
                      this.allotedDepartmentList.push(responsiblity.department);
                    }
                  }
                });
            }
          }
          if (sectionName === "IQAC") {
            this.iqacSectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.iqacSectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.iqacSectionAllowed = true;
              }
            });
          }
          if (sectionName === "Office & Accounts") {
            this.officeCoSectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.officeCoSectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.officeCoSectionAllowed = true;
              }
            });
          }

          if (sectionName === "Library") {
            this.librarySectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.librarySectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.librarySectionAllowed = true;
              }
            });
          }

          if (sectionName === "Green Initiatives") {
            this.greenInitiativesSectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.greenInitiativesSectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.greenInitiativesSectionAllowed = true;
              }
            });
          }

          if (sectionName === "Physical Education & Sports") {
            this.physicalEducationSportsSectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.physicalEducationSportsSectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.physicalEducationSportsSectionAllowed = true;
              }
            });
          }

          // TODO to add - Lib, Sports, Green
          if (
            _.includes(
              [
                "Personal",
                "Activities",
                "Admission, Examination, Progression & Support",
                "Services, Facilities & Infrastructure",
              ],
              sectionName
            )
          ) {
            this.personalSectionForms.push(section);
            roles.forEach((roleOfLoggedInUser) => {
              if (
                !this.personalSectionAllowed &&
                allowedDesignations.includes(roleOfLoggedInUser)
              ) {
                this.personalSectionAllowed = true;
              }
            });
          }
        });
      }
    });
    let iqac = false;
    let admin = false;
    this.collegeAdminAllowed = false;
    if (
      this.profile != undefined &&
      this.profile.responsiblities != undefined
    ) {
      this.profile.responsiblities.forEach((resp: any) => {
        if (resp) {
          if (
            resp.designation == "ACADEMIC_AUDITOR" ||
            resp.designation == "PRINCIPAL" ||
            resp.designation == "COLLAGE_MGMT_REPRESENTATIVE" ||
            resp.designation == "VICE_PRINCIPAL"
          ) {
            admin = true;
            this.collegeAdminAllowed = true;
          }
          if (resp.designation == "IQAC_ADMIN") {
            iqac = true;
          }
          if (resp.designation == "CRITERIA_COORDINATOR") {
            this.criteriaOrdinatorAllowed = true;
            this.allowedCrietrias.push(resp?.criteria);
          }
        }
      });
      if (!iqac && admin) {
        this.onlyCollegeAdminAllowed = true;
      }
      if (this.criteriaOrdinatorAllowed && this.allowedCrietrias.length > 0) {
        this.allowedCrietrias = this.allowedCrietrias.sort(
          (a, b) => this.romanStringToInt(a) - this.romanStringToInt(b)
        );
      }
    }
  }

  getLocalMetadata() {
    this.userDataService.getMetadata().then((metada) => {
      this.metadata = metada;
      this.segregateSectionWiseFormList();
    });
  }
  checkLoginStatus() {
    return this.userDataService.isLoggedIn().then((loggedIn) => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.isLoggedIn = loggedIn;
      // if logged in show the help widget
      if (this.platform.is("desktop")) {
        if (
          this.profile != undefined &&
          this.profile.responsiblities != undefined
        ) {
          let isSuperAdminorFacilitator = false;
          this.profile.responsiblities.forEach((resp: any) => {
            if (resp) {
              if (
                resp.designation == "SUPER_ADMIN" ||
                resp.designation == "FACILITATOR"
              ) {
                isSuperAdminorFacilitator = true;
              }
            }
          });
          if (!isSuperAdminorFacilitator) {
            window["FreshworksWidget"]("show");
            const launcherFrame = document.getElementById("launcher-frame");

            if (launcherFrame != null) {
              launcherFrame.style.maxWidth = "80px";
              launcherFrame.style.minWidth = "86px";
            }
          }
        }
      }
      if (
        this.platform.is("android") ||
        this.platform.is("ios") ||
        this.platform.is("mobile") ||
        this.platform.is("iphone")
      ) {
        window["FreshworksWidget"]("hide");
      }
      if (!loggedIn) {
        this.cleanupState();
      } else {
        this.checkwhichUserIsLogin();
      }
    }, 1000);
  }

  cleanupState() {
    this.profile = {};
    this.personalSectionForms = [];
    this.departmentSectionForms = [];
    this.iqacSectionForms = [];
    this.officeCoSectionForms = [];
    this.iqacSectionAllowed = false;
    this.personalSectionAllowed = false;
    this.departmentSectionAllowed = false;
    this.officeCoSectionAllowed = false;
    this.webc = false;
    this.isIQACADMIN = false;
    this.superUserLogin = false;
    this.isIQACCOADMIN = false;
    this.isIQACCOADMINOnly = false;
    window["FreshworksWidget"]("hide");
  }

  updateLoggedInStatusInbox(loggedIn: boolean) {
    setTimeout(() => {
      this.isLoggedIn = loggedIn;
      if (!loggedIn) {
        this.cleanupState();
      } else {
        this.getFormLiteInfoForLoggedInUser();
        this.fetchuserProfile();
        this.getLocalMetadata();
      }
    }, 2000);
  }

  listenForLoginEvents() {
    window.addEventListener("user:login", () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener("user:signup", () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener("user:logout", () => {
      this.updateLoggedInStatus(false);
    });

    window.addEventListener("user:force-logout", () => {
      this.updateLoggedInStatus(false);
      this.userDataService.presentToast(
        "You have been Logged out. Please re-login!",
        "warning"
      );
      // this.router.navigateByUrl('/login');
      this.userDataService.logout();
      this.navController.navigateRoot("/login");
    });

    window.addEventListener("form:quota-exhausted", () => {
      this.userDataService.presentToast(
        "Form Quota Exhausted Contact To Admin!",
        "danger"
      );
    });
  }
  listenForLoginEventsForInbox() {
    window.addEventListener("inbox:login", () => {
      this.updateLoggedInStatusInbox(true);
    });

    window.addEventListener("inbox:signup", () => {
      this.updateLoggedInStatusInbox(true);
    });

    window.addEventListener("inbox:logout", () => {
      this.updateLoggedInStatusInbox(false);
    });

    window.addEventListener("inbox:profile-available", () => {
      this.fetchuserProfile();
    });

    window.addEventListener("user:refresh", () => {
      this.fetchUpdatedProfile();
    });
  }

  checkwhichUserIsLogin() {
    this.userDataService.getLoggedInUserRole().then((roles) => {
      if (roles) {
        this.fetchuserProfile();
        if (roles.includes("SUPER_ADMIN")) {
          this.superUserLogin = true;
          this.isIQACADMIN = false;
          this.webc = false;
        } else if (roles.includes("IQAC_ADMIN")) {
          this.getProfileCompletionDetails();
          this.fetchAllCollegeTypeYearMappingsForCollegeId();
          this.isIQACADMIN = true;
          this.superUserLogin = false;
          // this.webc = false
        }
        if (roles.includes("WEB_EQUI")) {
          this.webc = true;
          this.superUserLogin = false;
          // this.isIQACADMIN = false;
        }
      } else {
        this.isLoggedIn = false;
        this.superUserLogin = false;
        this.isIQACADMIN = false;
        this.webc = false;
      }
      let iqac = false;
      let iqacCo = false;
      if (
        this.profile != undefined &&
        this.profile.responsiblities != undefined
      ) {
        this.profile.responsiblities.forEach((responsiblity) => {
          if (responsiblity) {
            if (responsiblity.designation == "IQAC_CO_ADMIN") {
              iqacCo = true;
              this.isIQACCOADMIN = true;
            }
            if (responsiblity.designation == "IQAC_ADMIN") {
              iqac = true;
            }
          }
        });
        if (!iqac && iqacCo) {
          this.isIQACCOADMINOnly = true;
        }
      }
    });
  }
  checkwhichUserIsLoginInbox() {
    this.getFormLiteInfoForLoggedInUser();
  }
  fetchuserProfile() {
    this.userDataService.getUserProfile().then((profile) => {
      if (profile) {
        this.profile = profile;
        if (this.profile.college != null) {
          this.subscriptionStatus = profile.college.status;
          if (this.subscriptionStatus == "SUBS_EXPIRED") {
            this.isExpired = false;
          }
        }
      }
    });
  }
  async confirmLogout() {
    const alert = await this.alertController.create({
      header: "Logout!",
      message: "Proceed with Logout?",
      buttons: [
        {
          text: "Stay",
          role: "cancel",
          handler: (blah) => {},
        },
        {
          text: "Logout",
          cssClass: "danger-bg",
          handler: () => {
            this.logout();
          },
        },
      ],
    });
    await alert.present();
  }

  async blackListedVersionAlert() {
    const alert = await this.alertController.create({
      header: "Please Update",
      message: "To enjoy our newest features.",
      backdropDismiss: false,
      buttons: [
        {
          text: "Update",
          cssClass: "Suceess-bg",
          handler: () => {
            this.market.open("in.easie.app").then((response) => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
          },
        },
      ],
    });
    await alert.present();
  }

  async supportedVersionAlert() {
    const alert = await this.alertController.create({
      header: "Warning!",
      message: "There is an Update available! Please update to use this App.",
      backdropDismiss: false,
      buttons: [
        {
          text: "Update",
          cssClass: "Suceess-bg",
          handler: () => {
            this.market.open("in.easie.app").then((response) => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
          },
        },
        {
          text: "Skip",
          role: "cancel",
          handler: () => {
            this.userDataService.setIsSkipUpdate(true);
          },
        },
      ],
    });
    await alert.present();
  }

  logout() {
    this.userDataService.logout().then(() => {
      this.isLoggedIn = false;
      this.userDataService.presentToast("Successfully Logged out.", "warning");
      this.navController.setDirection("root");
      return this.router.navigateByUrl("/login", { replaceUrl: true });
    });
    this.updateLoggedInStatus(false);
    this.updateLoggedInStatusInbox(false);
  }

  setActiveSection(activeSection: String) {
    this.userDataService.saveCurentSection(activeSection);
    this.activeSection = activeSection;
  }

  setCurrentCriteria(currentCriteria: String) {
    this.currentCriteria = currentCriteria;
    this.userDataService.saveCurrentCriteria(currentCriteria);
  }

  fetchUpdatedProfile() {
    this.userDataService.getToken().then((token) => {
      this.userDataService.getUserProfile().then((profile) => {
        this.userProfileService
          .getUserProfile(token, profile.emailId)
          .subscribe((profile) => {
            this.profile = profile;
            this.userdataService.saveUserProfile(profile).then((response) => {
              this.userProfileService
                .fetchTokenDtoObject(token, profile)
                .subscribe((tokenDTO) => {
                  this.userDataService
                    .saveSecurityToken(tokenDTO)
                    .then((token) => {
                      this.segregateSectionWiseFormList();
                    });
                });
            });
          });
      });
    });
  }

  async formQuota() {
    this.userDataService.getLoggedInUserRole().then((roles) => {
      if (roles) {
        if (!roles.includes("SUPER_ADMIN")) {
          this.userDataService.isLoggedIn().then(async (loggedIn) => {
            if (loggedIn) {
              let token = await this.userDataService.getToken();
              this.dashboardService
                .getUserQuota(token)
                .subscribe((userQuota) => {
                  if (userQuota.quotaExhausted) {
                    this.quotaExhausted = false;
                  }
                });
            }
          });
        }
      }
    });
  }

  async handleActiveNavigation() {
    const activeSection = await this.userdataService.getCurrentActiveSection();
    this.activeSection = activeSection;
  }
}
