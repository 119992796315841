import { Observable, throwError } from 'rxjs';
import { CommonService } from './../common/common.service';
import { UserDataService } from "./../userData/user-data.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class YearCollegeTypeMappingService {
  constructor(public http: HttpClient, public userService: UserDataService) {}

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  saveCollegeTypeYearMapping(token:any,mapping:any):Observable<any>{
    let url = CommonService.BASE_URL + '/api/v1/secure/collegetype/save';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url,mapping,httpOptions)
    .pipe(
      map(this.extractData),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          if (error.status === 403) {
            window.dispatchEvent(new CustomEvent('user:force-logout'));
          }
        }
        // return an observable with a user-facing error message
        return throwError(error);
      })
    );
  }

  fetchCollegeTypeYearMapping(token:any,mapping:any):Observable<any>{
    let url = CommonService.BASE_URL + '/api/v1/secure/collegetype/fetch';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url,mapping,httpOptions)
    .pipe(
      map(this.extractData),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          if (error.status === 403) {
            window.dispatchEvent(new CustomEvent('user:force-logout'));
          }
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      })
    );
  }

  fetchCollegeTypeForYearFromAndCollege(token:any,mapping:any):Observable<any>{
    let url = CommonService.BASE_URL + '/api/v1/secure/collegetype/search';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url,mapping,httpOptions)
    .pipe(
      map(this.extractData),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          if (error.status === 403) {
            window.dispatchEvent(new CustomEvent('user:force-logout'));
          }
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      })
    );
  }
}
