import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { UserDataService } from '../userData/user-data.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(public http: HttpClient, public userService: UserDataService) {}

  private extractData(res: Response) {
    let body = res;
    return body || 0;
  }

  getAllSubmittedFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/submitted/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getTotalFormsCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/college/form/count/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllApprovedCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/approved/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllDraftFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/draft/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllPendingOfficeCoFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/pending/office/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllUpdatedOfficeCoFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/updated/office/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getPendingWebCoFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/pending/web/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getUserQuota(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/user/quota/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }


  // Office Counts APIS START -------------------------
  getOfficeDraftFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/office/draft/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  
  getOfficeSubmittedFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/office/submitted/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getOfficeApprovedFormCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/office/approved/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  // Office Counts APIS END   -------------------------

  getAllUSerCount(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/user/count/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllSubmittedFormsCountByMe(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/submitted/count/teacher/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllDraftFormsCountByMe(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/draft/count/teacher/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllApprovedFormsCountByMe(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/approved/count/teacher/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllSubmittedFormsCountByGreenCO(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/submitted/count/greenCO/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllDraftFormsCountByGreenCO(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/draft/count/greenCO/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllApprovedFormsCountByGreenCO(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/approved/count/greenCO/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllSubmittedFormsCountByHod(token:any,departmentId:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/submitted/count/hod/'+departmentId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllDraftFormsCountByHod(token:any,departmentId:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/draft/count/hod/'+departmentId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllApprovedFormsCountByHod(token:any,departmentId:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/approved/count/hod/'+departmentId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllSubmittedFormsCountBySportTeacher(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/submitted/count/sport/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllDraftFormsCountBySportTeacher(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/draft/count/sport/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllApprovedFormsCountBySportTeacher(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/approved/count/sport/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllSubmittedFormsCountByLibrarian(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/submitted/count/librarian/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllDraftFormsCountByLibrarian(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/draft/count/librarian/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllApprovedFormsCountByLibrarian(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/approved/count/librarian/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getPendingWebCoFormCountUsers(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/pending/web/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getUpdatedWebCoFormCountUsers(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/updated/web/count/iqac/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllCriteriaCoordinatorsCounts(token:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/dashboard/criteria/counts/';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
}
