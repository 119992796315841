import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from 'src/app/providers/userData/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionStatusGuard implements CanActivate {
  constructor(private router:Router, public userDataService: UserDataService,){}
  subscriptionStatus : boolean = true;
  profile:any = {};
  college:any = [];
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.userDataService.getUserProfile().then((profile) => {
        if (profile) {
          this.profile = profile;
          console.log(this.profile)
          this.college =  this.profile.responsiblities.filter(x => x.designation == "SUPER_ADMIN");
          
          if(this.profile.college != null)
          {
              if(this.college.length > 0)
              {
                this.subscriptionStatus = true;
              }else if (this.profile.college.status == "ONBOARDED") {
                this.subscriptionStatus = false;
                return this.router.createUrlTree(['home']);
              }else
              {
                this.subscriptionStatus = true;
              }
          } else
          {
            this.subscriptionStatus = true;
          }
        }
      });
      return this.subscriptionStatus;
  }
  
}
