import { Constants } from "src/app/util/constants";
import { YearCollegeTypeMappingService } from "./../../providers/yearCollegeMapping/year-college-type-mapping.service";
import { DatePipe } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import {
  LoadingController,
  AlertController,
  ModalController,
  NavController,
} from "@ionic/angular";
import { UserProfileService } from "./../../providers/userProfile/user-profile.service";
import { UserDataService } from "./../../providers/userData/user-data.service";
import { Component, OnInit } from "@angular/core";

import { DateTime } from "luxon";
@Component({
  selector: "app-year-management",
  templateUrl: "./year-management.page.html",
  styleUrls: ["./year-management.page.scss"],
})
export class YearManagementPage implements OnInit {
  yearToCollegeMapping: any = [];
  selectedYearFrom: any = "";
  selectedYearTo: any = "";
  selectedCollegeType = "";
  yearObject: Date;
  collegeId: any;
  selectedYearsList: any = [];
  userProfile: any;
  metadata: any = {};

  fromYearOptions: string[] = [];
  constructor(
    public userDataService: UserDataService,
    public userProfileService: UserProfileService,
    public loadingController: LoadingController,
    public route: Router,
    public alertController: AlertController,
    public router: ActivatedRoute,
    public modalController: ModalController,
    public navController: NavController,
    public datePipe: DatePipe,
    public collegeTypeYearMappingService: YearCollegeTypeMappingService
  ) {}

  ngOnInit() {
    this.userDataService.getUserProfile().then((profile) => {
      this.collegeId = profile.college.id;
      this.userProfile = profile;
      this.fetchAllCollegeTypeYearMappingsForCollegeId();
    });
    this.selectedYearFrom = this.datePipe.transform(this.yearObject, "yyyy");
    this.userDataService.getMetadata().then((metadata) => {
      this.metadata = metadata;
    });

    const now = DateTime.local();
    for (let index = now.year; index >= 1924; index--) {
      this.fromYearOptions.push(index.toString());
    }
  }

  // onYearChange() {
  //   this.selectedYearFrom = this.datePipe.transform(this.yearObject, "yyyy");
  // }

  // yearFromChange(event) {
  //   this.selectedCollegeType = "";
  //   let enteredValue = event.target.value;
  //   if (enteredValue !== "" && enteredValue !== undefined) {
  //     let millis = Date.parse(event.target.value.substring(0, 10));
  //     let millisOfCurrentYear = Date.parse(event.target.value.substring(0, 10));
  //     let nextYearOfSelectedDate = new Date(millis);
  //     let currentYear = new Date(millisOfCurrentYear);
  //     nextYearOfSelectedDate.setDate(nextYearOfSelectedDate.getDate() + 366);
  //     this.selectedYearTo = this.datePipe.transform(
  //       nextYearOfSelectedDate,
  //       "yyyy"
  //     );
  //     this.selectedYearFrom = this.datePipe.transform(currentYear, "yyyy");
  //     this.isYearValidForAC();
  //   }
  // }

  async confirmationAlertForMappingAddition() {
    if (this.isValid()) {
      const alert = await this.alertController.create({
        header: "Add Mapping?",
        message:
          this.selectedYearFrom +
          "-" +
          this.selectedYearTo +
          "  -->  " +
          this.selectedCollegeType,
        buttons: [
          {
            text: "Add",
            handler: () => {
              this.addCollegeDateMapping();
            },
          },
          {
            text: "Cancel",
            handler: () => {},
          },
        ],
      });
      await alert.present();
    }
  }

  addCollegeDateMapping() {
    if (this.isValid()) {
      let mapping = {
        yearFrom: this.selectedYearFrom,
        yearTo: this.selectedYearTo,
        type: this.selectedCollegeType,
        collegeId: this.collegeId,
        user: this.userProfile,
      };
      //this.yearToCollegeMapping.push(mapping);
      //this.selectedYearsList.push(this.selectedYear);
      this.userDataService.getToken().then((token) => {
        this.collegeTypeYearMappingService
          .saveCollegeTypeYearMapping(token, mapping)
          .subscribe(
            (response) => {
              this.fetchAllCollegeTypeYearMappingsForCollegeId();
              this.userDataService.presentToast(
                "Mapping added successfully!.",
                "success"
              );
              this.modalController.dismiss();
            },
            (error) => {
              console.log(error);
              if (error.status == 422) {
                this.userDataService.presentToast(
                  "Mapping already exists.",
                  "warning"
                );
              } else {
                this.userDataService.presentToast(
                  "Error while saving mapping.",
                  "danger"
                );
              }
            }
          );
      });
      this.clearInput();
    }
  }

  handleFromYearSelection(selectedYear: string) {
    console.log(selectedYear);
    this.selectedYearFrom = selectedYear;
    this.selectedYearTo = (parseInt(selectedYear) + 1).toString();
  }

  handleCollegeTypeChange(selectedType: string) {
    console.log(selectedType);
    this.selectedCollegeType = selectedType;
  }

  // collegeTypeChange() {
  //   this.isYearValidForAC();
  // }

  isYearValidForAC() {
    if (
      this.selectedCollegeType == "AC" &&
      parseInt(this.selectedYearFrom) < 2022
    ) {
      this.userDataService.presentToast(
        "Affiliated College type (AC) can not be assigned before 2022!",
        "warning"
      );
      return false;
    }
    return true;
  }

  isValid() {
    if (
      this.selectedYearFrom == undefined ||
      this.selectedYearFrom == null ||
      this.selectedYearFrom == ""
    ) {
      this.userDataService.presentToast("Please select year", "warning");
      return false;
    }
    if (
      this.selectedCollegeType == undefined ||
      this.selectedCollegeType == null ||
      this.selectedCollegeType == ""
    ) {
      this.userDataService.presentToast(
        "Please select college type",
        "warning"
      );
      return false;
    }
    if (!this.isYearValidForAC()) {
      return false;
    }
    if (this.selectedYearsList.includes(this.selectedYearFrom)) {
      this.userDataService.presentToast(
        "Year to college type mapping already exists",
        "warning"
      );
      return false;
    }
    return true;
  }

  fetchAllCollegeTypeYearMappingsForCollegeId() {
    this.userDataService.getToken().then((token) => {
      this.collegeTypeYearMappingService
        .fetchCollegeTypeYearMapping(token, {
          collegeId: this.collegeId,
        })
        .subscribe(
          (response) => {
            console.log(response);
            this.yearToCollegeMapping = response.mappings
              ? response.mappings.sort(Constants.YearFromComparator)
              : [];
          },
          (error) => {}
        );
    });
  }

  clearInput() {
    this.selectedYearFrom = "";
    this.selectedYearTo = "";
    this.yearObject = null;
    this.selectedCollegeType = "";
  }
}
