<ion-app>
  <nav class="navbar navbar-expand-lg navbar-dark bg-white static-top align-right">
    <a class="navbar-brand header-logo" href="#" *ngIf="!isBetaVersion">
      <img src="assets/images/logo-new.png" alt="EASIE Logo" />
    </a>
    <a class="navbar-brand header-logo" href="#" *ngIf="isBetaVersion">
      <img src="assets/images/logo-beta-release.png" alt="EASIE Logo" />
    </a>

    <div class="row p-0 text-center">
      <div class="col-12 pr-4 mt-1">
        <button class="navbar-toggler color-brand-success pb-0" type="button" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
          aria-label="Toggle navigation">
          <i class="fas fa-bars color-brand-primary"></i>
        </button>
      </div>
    </div>

    <div class="collapse navbar-collapse pt-3 easie-nav-bar" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item for-lg-device">
          <a class="nav-link" routerLink="/home" routerLinkActive="selected" routerDirection="root" href="#"
            (click)="setActiveSection('HOME')">
            <i class="fas fa-home fa-lg"></i>
          </a>
        </li>
        <li class="nav-item for-sm-device" data-toggle="collapse" data-target="#navbarNavDropdown">
          <a class="nav-link" routerLink="/home" routerLinkActive="selected" routerDirection="root" href="#"
            (click)="setActiveSection('HOME')">
            <i class="fas fa-home"></i> Home
          </a>
        </li>

        <li class="nav-item dropdown" *ngIf="superUserLogin && isLoggedIn">
          <a class="nav-link dropdown-toggle" [class]="activeSection === 'SUPER_USER_MENU' ? 'selected' : ''" href="#"
            id="navbarDropdownSuperMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            (click)="setActiveSection('SUPER_USER_MENU')">
            <i class="fas fa-user-tie"></i> Super User
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownSuperMenuLink">
            <span class="for-lg-device">
              <a class="dropdown-item" href="#" *ngFor="let p of appPages; let i = index" [routerLink]="[p.url]"
                routerLinkActive="selected" (click)="setActiveSection('SUPER_USER_MENU')">
                <i class="{{p.icon}}"></i> &nbsp;{{p.title}}
              </a>
            </span>
            <span class="for-sm-device">
              <a class="dropdown-item" href="#" *ngFor="let p of appPages; let i = index" [routerLink]="[p.url]"
                routerLinkActive="selected" (click)="setActiveSection('SUPER_USER_MENU')" data-toggle="collapse"
                data-target="#navbarNavDropdown">
                <i class="{{p.icon}}"></i> &nbsp;{{p.title}}
              </a>

            </span>
          </div>
        </li>

        <li class="nav-item dropdown for-lg-device"
          *ngIf="isIQACADMIN && !isIQACCOADMINOnly && !onlyCollegeAdminAllowed && isLoggedIn && subscriptionStatus != 'ONBOARDED'">
          <a class="nav-link dropdown-toggle" [class]="activeSection === 'IQAC_MENU' ? 'selected' : ''" href="#"
            id="navbarDropdownIQACMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            (click)="setActiveSection('IQAC_MENU')">
            <i class="fas fa-user-cog"></i> IQAC Menu
          </a>
          <div class="dropdown-menu" *ngIf="isIQACADMIN && !isIQACCOADMINOnly && !onlyCollegeAdminAllowed"
            aria-labelledby="navbarDropdownIQACMenuLink">
            <a class="nav-link dropdown-item" href="#" *ngFor="let p of appPagesIQAC; let i = index"
              [routerLink]="[p.url]" routerLinkActive="selected" (click)="setActiveSection('IQAC_MENU')">
              <i class="{{p.icon}}"></i> &nbsp;{{p.title}}
            </a>
          </div>
        </li>

        <li class="nav-item dropdown for-lg-device" *ngIf="(isIQACCOADMIN && isIQACCOADMINOnly) && isLoggedIn">
          <a class="nav-link dropdown-toggle" [class]="activeSection === 'IQAC_CO_MENU' ? 'selected' : ''" href="#"
            id="navbarDropdownIQACMenuLinkCo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            (click)="setActiveSection('IQAC_CO_MENU')">
            <i class="fas fa-user-tie"></i> IQAC Co-admin Menu
          </a>
          <div class="dropdown-menu" *ngIf="(isIQACCOADMIN && isIQACCOADMINOnly)"
            aria-labelledby="navbarDropdownIQACMenuLinkCo">
            <a class="nav-link dropdown-item" href="#" *ngFor="let p of appPagesIQACCoAdmin; let i = index"
              [routerLink]="[p.url]" routerLinkActive="selected" (click)="setActiveSection('IQAC_CO_MENU')">
              <i class="{{p.icon}}"></i> &nbsp;{{p.title}}
            </a>
          </div>
        </li>

        <span
          *ngIf="iqacSectionAllowed&& !superUserLogin && isLoggedIn && isIQACADMIN && profile && subscriptionStatus != 'ONBOARDED'">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [class]="activeSection === 'IQAC_DASHBORAD' ? 'selected' : ''" href="#"
              id="navbarDropdownIQACFormMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-globe"></i> IQAC
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownIQACFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/iqac-dashboard" routerDirection="root"
                  routerLinkActive="selected" (click)="setActiveSection('IQAC_DASHBORAD')">
                  <i class="fas fa-user-cog"></i> Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/iqac-dashboard" routerDirection="root"
                  routerLinkActive="selected" data-toggle="collapse" data-target="#navbarNavDropdown"
                  (click)="setActiveSection('IQAC_DASHBORAD')">
                  <i class="fas fa-user-cog"></i>&nbsp;Inbox
                </a>
              </span>
              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownIQACFormCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('IQAC_DASHBORAD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownIQACFormCreateMenuLink">

                <span class="for-lg-device">
                  <a class="nav-link dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of iqacSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
                <span class="for-sm-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of iqacSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>


              </div>
            </div>
          </li>
        </span>


        <span *ngIf="officeCoSectionAllowed && !superUserLogin && isLoggedIn  && profile">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [class]="activeSection === 'OFFICE_DASHBORAD' ? 'selected' : ''"
              href="#" id="navbarDropdownOfficeFormMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fas fa-user-tie"></i> Office Co.
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownOfficeFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/office-coordinator-dashboard"
                  routerDirection="root" routerLinkActive="selected" (click)="setActiveSection('OFFICE_DASHBORAD')">
                  <i class="fas fa-user-tie"></i> Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/office-coordinator-dashboard"
                  routerDirection="root" routerLinkActive="selected" data-toggle="collapse"
                  data-target="#navbarNavDropdown" (click)="setActiveSection('OFFICE_DASHBORAD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownOfficeCoCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('OFFICE_DASHBORAD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownOfficeCoCreateMenuLink">


                <span class="for-lg-device">
                  <a class="nav-link dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of officeCoSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>

                <span class="for-sm-device">
                  <a class="nav-link dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of officeCoSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </span>

        <span *ngIf="isLoggedIn && collegeAdminAllowed">
          <span class="for-lg-device">
            <li class="nav-item">
              <a class="nav-link" routerLink="/admin-dashboard" routerDirection="root"
                [class]="activeSection === 'ADMIN_DASHBORAD' ? 'selected' : ''" href="#"
                (click)="setActiveSection('ADMIN_DASHBORAD')">
                <i class="fas fa-user-shield"></i> Admin</a>
            </li>
          </span>
          <span class="for-sm-device">
            <li class="nav-item" data-toggle="collapse" data-target="#navbarNavDropdown">
              <a class="nav-link" routerLink="/admin-dashboard" routerDirection="root"
                [class]="activeSection === 'ADMIN_DASHBORAD' ? 'selected' : ''" href="#"
                (click)="setActiveSection('ADMIN_DASHBORAD')">
                <i class="fas fa-user-shield"></i> Admin</a>
            </li>
          </span>
        </span>

        <span *ngIf="departmentSectionAllowed && !superUserLogin && isLoggedIn">
          <li class="nav-item dropdown" *ngFor="let dept of  allotedDepartmentList">
            <a class="nav-link dropdown-toggle" [class]="activeSection === 'DEPARTMENT_DASHBOARD' ? 'selected' : ''"
              href="#" id="navbarDropdownDeptFormMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" *ngIf="isLoggedIn && subscriptionStatus != 'ONBOARDED'">
              <i class="fas fa-building"></i> {{dept.name}} Dept.
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownPersonalFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" [routerLink]="['/shared-form-inbox',dept.id]"
                  routerDirection="root" routerLinkActive="selected" (click)="setActiveSection('DEPARTMENT_DASHBOARD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" [routerLink]="['/shared-form-inbox',dept.id]"
                  routerDirection="root" routerLinkActive="selected" data-toggle="collapse"
                  data-target="#navbarNavDropdown" (click)="setActiveSection('DEPARTMENT_DASHBOARD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownDeptFormCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('DEPARTMENT_DASHBOARD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownDeptFormCreateMenuLink">
                <span class="for-lg-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of departmentSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,dept.id]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
                <span class="for-sm-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of departmentSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,dept.id]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </span>

        <span *ngIf="librarySectionAllowed && !superUserLogin && isLoggedIn && profile">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [class]="activeSection === 'LIBRARY_DASHBORAD' ? 'selected' : ''"
              href="#" id="navbarDropdownOfficeFormMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fas fa-book-open"></i> Library
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownOfficeFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/library-knowledge-resources-dashboard"
                  routerDirection="root" routerLinkActive="selected" (click)="setActiveSection('LIBRARY_DASHBORAD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/library-knowledge-resources-dashboard"
                  routerDirection="root" routerLinkActive="selected" data-toggle="collapse"
                  data-target="#navbarNavDropdown" (click)="setActiveSection('LIBRARY_DASHBORAD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownOfficeCoCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('LIBRARY_DASHBORAD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownOfficeCoCreateMenuLink">
                <span class="for-lg-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of librarySectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
                <span class="for-sm-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of librarySectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </span>



        <span *ngIf="personalSectionAllowed && !superUserLogin && isLoggedIn && personalSectionForms.length >0">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [class]="activeSection === 'PERSONAL_DASHBORD' ? 'selected' : ''"
              href="#" id="navbarDropdownPersonalFormMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fas fa-chalkboard-teacher"></i> Teacher
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownPersonalFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" [routerLink]="['/shared-form-inbox',undefined]"
                  routerDirection="root" routerLinkActive="selected" (click)="setActiveSection('PERSONAL_DASHBORD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" [routerLink]="['/shared-form-inbox',undefined]"
                  routerDirection="root" routerLinkActive="selected" data-toggle="collapse"
                  data-target="#navbarNavDropdown" (click)="setActiveSection('PERSONAL_DASHBORD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>

              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownPersonalFormCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('PERSONAL_DASHBORD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownPersonalFormCreateMenuLink">

                <span class="for-lg-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of personalSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;<ion-label class="ion-text-wrap">{{ p.label }}</ion-label>
                  </a>
                </span>
                <span class="for-sm-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of personalSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;<ion-label class="ion-text-wrap">{{ p.label }}</ion-label>
                  </a>
                </span>
              </div>
            </div>
          </li>
        </span>

        <span *ngIf="greenInitiativesSectionAllowed && !superUserLogin && isLoggedIn  && profile">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"
              [class]="activeSection === 'GREEN_INITIATIVES_DASHBOARD' ? 'selected' : ''" href="#"
              id="navbarDropdownOfficeFormMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-leaf"></i> Green Initiatives
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownOfficeFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/green-initiatives-dashboard"
                  routerDirection="root" routerLinkActive="selected"
                  (click)="setActiveSection('GREEN_INITIATIVES_DASHBOARD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/green-initiatives-dashboard"
                  routerDirection="root" routerLinkActive="selected" data-toggle="collapse"
                  data-target="#navbarNavDropdown" (click)="setActiveSection('GREEN_INITIATIVES_DASHBOARD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownOfficeCoCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('GREEN_INITIATIVES_DASHBORAD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownOfficeCoCreateMenuLink">
                <span class="for-lg-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of greenInitiativesSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
                <span class="for-sm-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of greenInitiativesSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </span>

        <span *ngIf="physicalEducationSportsSectionAllowed && !superUserLogin && isLoggedIn  && profile">
          <li class="nav-item dropdown for-lg-device">
            <a class="nav-link dropdown-toggle"
              [class]="activeSection === 'PHYSICAL_AND_SPORT_DASHBORAD' ? 'selected' : ''" href="#"
              id="navbarDropdownOfficeFormMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-running"></i> Physical Edu. & Sports
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownOfficeFormMenuLink">
              <span class="for-lg-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/physical-education-and-sports-dashboard"
                  routerDirection="root" routerLinkActive="selected"
                  (click)="setActiveSection('PHYSICAL_AND_SPORT_DASHBORAD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <span class="for-sm-device">
                <a class="nav-link dropdown-item" href="#" routerLink="/physical-education-and-sports-dashboard"
                  routerDirection="root" routerLinkActive="selected" data-toggle="collapse"
                  data-target="#navbarNavDropdown" (click)="setActiveSection('PHYSICAL_AND_SPORT_DASHBORAD')">
                  <i class="fas fa-inbox"></i>&nbsp;Inbox
                </a>
              </span>
              <a class="nav-link dropdown-toggle" href="#" *ngIf="isExpired && quotaExhausted"
                id="navbarDropdownOfficeCoCreateMenuLink" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" (click)="setActiveSection('PHYSICAL_AND_SPORT_DASHBORAD')">
                <i class="far fa-edit"></i>&nbsp;Create
              </a>
              <div class="dropdown-menu dropdown-menu-l2" aria-labelledby="navbarDropdownOfficeCoCreateMenuLink">
                <span class="for-lg-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of physicalEducationSportsSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
                <span class="for-sm-device">
                  <a class="dropdown-item" href="#" routerLinkActive="selected"
                    *ngFor="let p of physicalEducationSportsSectionForms; let i = index"
                    [routerLink]="['/form-navigation', p.id,undefined]" routerDirection="root" data-toggle="collapse"
                    data-target="#navbarNavDropdown">
                    <i class="fab fa-wpforms"></i> &nbsp;{{ p.label }}
                  </a>
                </span>
              </div>
            </div>
          </li>
        </span>

        <!-- Criteria Co links -->
        <span *ngIf="criteriaOrdinatorAllowed && isLoggedIn  && profile">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [class]="activeSection === 'CRITERIA_DASHBOARD' ? 'selected' : ''"
              href="#" id="navbarDropdownCriteriaCoMenuLink" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fas fa-user-cog"></i> Criteria Co
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownCriteriaCoMenuLink">
              <a class="nav-link dropdown-item" *ngFor="let crt of allowedCrietrias; let i = index" href="#"
                routerLink="/criteria-dashboard/{{crt}}" routerDirection="root" routerLinkActive="selected"
                (click)="setActiveSection('CRITERIA_DASHBOARD')">
                <i class="fas fa-bookmark"></i> &nbsp;Criteria {{crt}}
              </a>
            </div>
          </li>
        </span>

        <span *ngIf="isLoggedIn && webc">
          <span class="for-lg-device">
            <li class="nav-item">
              <a class="nav-link" routerLink="/web-coordinator-dashboard" routerDirection="root"
                routerLinkActive="selected" href="#" (click)="setActiveSection('WEBC_DASHBORAD')">
                <i class="fas fa-user"></i>&nbsp;Web Coordinator</a>
            </li>
          </span>
          <span class="for-sm-device">
            <li class="nav-item" data-toggle="collapse" data-target="#navbarNavDropdown">
              <a class="nav-link" routerLink="/web-coordinator-dashboard" routerDirection="root"
                routerLinkActive="selected" href="#" (click)="setActiveSection('WEBC_DASHBORAD')">
                <i class="fas fa-user"></i>&nbsp;Web Coordinator</a>
            </li>
          </span>
        </span>

        <span *ngIf="isLoggedIn && isFacilitator">
          <span class="for-lg-device">
            <li class="nav-item">
              <a class="nav-link" routerLink="/facilitator-dashboard" routerLinkActive="selected" routerDirection="root"
                href="#" (click)="setActiveSection('FASCILITATOR_DASHBOARD')">
                <i class="fas fa-user"></i>&nbsp;Facilitator Inbox</a>
            </li>
          </span>
          <span class="for-sm-device">
            <li class="nav-item" data-toggle="collapse" data-target="#navbarNavDropdown">
              <a class="nav-link" routerLink="/facilitator-dashboard" routerLinkActive="selected" routerDirection="root"
                href="#" (click)="setActiveSection('FASCILITATOR_DASHBOARD')">
                <i class="fas fa-user"></i>&nbsp;Facilitator Inbox</a>
            </li>
          </span>
        </span>

        <li class="nav-item for-lg-device" *ngIf="isLoggedIn">
          <a class="nav-link"
            [ngClass]="!isProfileCompletionCheck && isIQACADMIN && !isIQACCOADMIN ? 'text-warning' : ''"
            routerLink="/user-profile" routerLinkActive="selected" routerDirection="root" href="#"
            (click)="setActiveSection('PROFILE')">
            <i class='fa fa-circle animated zoomIn slow infinite incomplete-profile-indicator'
              *ngIf="!isProfileCompletionCheck && isIQACADMIN && !isIQACCOADMIN"></i>
            <div
              [ngClass]="!isProfileCompletionCheck && isIQACADMIN && !isIQACCOADMIN ? 'profile-chip-warning' : 'profile-chip'">
              <i class="fas fa-user-circle fa-lg"></i>
              <span class="chip-email">{{profile.emailId}}</span>
            </div>
          </a>
        </li>
        <li class="nav-item for-sm-device" *ngIf="isLoggedIn" data-toggle="collapse" data-target="#navbarNavDropdown">
          <a class="nav-link"
            [ngClass]="!isProfileCompletionCheck && isIQACADMIN && !isIQACCOADMIN ? 'text-warning' : ''"
            routerLink="/user-profile" routerLinkActive="selected" routerDirection="root" href="#"
            (click)="setActiveSection('PROFILE')">
            <i class='fa fa-circle animated zoomIn slow infinite incomplete-profile-indicator'
              *ngIf="!isProfileCompletionCheck && isIQACADMIN && !isIQACCOADMIN"></i>
            <div
              [ngClass]="!isProfileCompletionCheck && isIQACADMIN && !isIQACCOADMIN ? 'profile-chip-warning' : 'profile-chip'">
              <i class="fas fa-user-circle fa-lg"></i>
              <span class="chip-email">{{profile.emailId}}</span>
            </div>


          </a>

        </li>
        <li class="nav-item for-lg-device" *ngIf="!isLoggedIn">
          <a class="nav-link" routerLink="/login" routerLinkActive="selected" routerDirection="root" href="#">
            <i class="fas fa-sign-in-alt"></i> Login</a>
        </li>
        <li class="nav-item for-sm-device" *ngIf="!isLoggedIn" data-toggle="collapse" data-target="#navbarNavDropdown">
          <a class="nav-link" routerLink="/login" routerLinkActive="selected" routerDirection="root" href="#">
            <i class="fas fa-sign-in-alt"></i> Login</a>
        </li>


        <li class="nav-item for-lg-device" *ngIf="isLoggedIn">
          <a class="nav-link text-danger" data-toggle="modal" data-target="#logoutModal" href="#">
            <i class="fas fa-sign-out-alt fa-lg"></i></a>
        </li>
        <li class="nav-item for-sm-device" *ngIf="isLoggedIn" data-toggle="collapse" data-target="#navbarNavDropdown">
          <a class="nav-link text-danger" data-toggle="modal" data-target="#logoutModal" href="#">
            Logout <i class="fas fa-sign-out-alt"></i></a>
        </li>

      </ul>
    </div>
  </nav>


  <!-- Page Content -->
  <div class="container">
    <ion-router-outlet id="main-content"></ion-router-outlet>
    <!-- <a class="github-fork-ribbon left-bottom fix" href="" data-ribbon="Beta" title="Beta" style="left: -40px; bottom: -40px;">Beta</a> -->
  </div>
  <!-- /.container -->
</ion-app>


<!-- Modal -->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header text-white bg-brand-danger">
        <h5 class="modal-title" id="exampleModalLabel">Logout</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body">
        Confirm Logout?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark bg-brand-light" data-dismiss="modal">Close <i
            class="fas fa-times"></i></button>
        <button type="button" class="btn bg-brand-danger text-white" (click)="logout()" data-dismiss="modal">Logout <i
            class="fas fa-sign-out-alt"></i></button>
      </div>
    </div>
  </div>
</div>