import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Camera } from '@ionic-native/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { File } from '@ionic-native/file/ngx';
// import { FileChooser } from '@ionic-native/file-chooser/ngx';
// import { FilePath } from '@ionic-native/file-path/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { ProfileModelPageModule } from './pages/profile-model/profile-model.module';
import { SharedModule } from './pages/shared/shared/shared.module';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TermsAndConditionModelPageModule } from './pages/terms-and-condition-model/terms-and-condition-model.module';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import {AutosizeModule} from 'ngx-autosize';
// import {FCM} from '@ionic-native/fcm/ngx'
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    ProfileModelPageModule,
    TermsAndConditionModelPageModule,
    FormsModule,
    SharedModule,
    AutosizeModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AppVersion,
    Market,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ,Camera, WebView, File, DatePipe, Chooser,DecimalPipe, FileOpener
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
