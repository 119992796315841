import { Injectable } from '@angular/core';
import { environment } from "./../../../environments/environment";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public static BASE_URL: string = environment.baseApiUrl;

  constructor() { }
  public static log(message, ...params) {
    if(_.includes(window.location.href, 'lg=1')) {
      console.log(message, ...params);
    }
  }
}
