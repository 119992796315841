export const environment = {
  production: false,
  demo: true,
  name: "Staging",
  baseApiUrl: "https://staging-api.easie.in/easie",
  firebaseConfig: {
    apiKey: "AIzaSyA9aDGNUlaTY--fjzq_amgcoWs6xh-VDbY",
    authDomain: "easie-staging.firebaseapp.com",
    projectId: "easie-staging",
    storageBucket: "easie-staging.appspot.com",
    messagingSenderId: "18171683377",
    appId: "1:18171683377:web:e462929ddc604e94f42b10",
    measurementId: "G-5G3ZKC6M80",
  },
};
// baseApiUrl: 'https://cors-anywhere.herokuapp.com/http://107.180.104.109:7274/easie'
