import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserDataService } from '../../userData/user-data.service';
import { CommonService } from '../../common/common.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormsSharingService {

  constructor(
    private http: HttpClient,
    public userService: UserDataService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  shareForm(token: any, payload: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/sharefrom';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url, payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  // shareFormToIQAC(token: any, payload: any): Observable<any> {
  //   let url = CommonService.BASE_URL + '/api/v1/secure/iqac/forms/sharing/sharefrom';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   }
  //   return this.http.post(url, payload, httpOptions)
  //     .pipe(
  //       map(this.extractData),
  //       catchError(error => {
  //         if (error.error instanceof ErrorEvent) {
  //           // A client-side or network error occurred. Handle it accordingly.
  //           console.error('An error occurred:', error.error.message);
  //         } else {
  //           // The backend returned an unsuccessful response code.
  //           // The response body may contain clues as to what went wrong,
  //           console.error(
  //             `Backend returned code ${error.status}, ` +
  //             `body was: ${error.error}`);
  //           if (error.status === 403) {
  //             window.dispatchEvent(new CustomEvent('user:force-logout'));
  //           }
  //         }
  //         // return an observable with a user-facing error message
  //         return throwError(
  //           'Something bad happened; please try again later.');
  //       })
  //     );
  // }
  shareFormToWebC(token: any, payload: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/webc/forms/sharing/sharefrom';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url, payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
            if(error.status === 422){
            return  this.userService.presentToast("Web-Co yet not added for your college please add and retry","warning");
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  getAllSharedWithMeApprovedPerticularForm(token: any, userId: any,formTypeId:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/shared-with-me-approved/' + userId + '/' + formTypeId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  getAllSharedWithMePerticularForm(token: any, userId: any,formTypeId:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/shared-with-me/' + userId + '/' + formTypeId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  // getAllSharedWithMePerticularFormWebC(token: any, userId: any,formTypeId:any): Observable<any> {
  //   let url = CommonService.BASE_URL + '/api/v1/secure/webc/forms/sharing/shared-with-me/' + userId + '/'+ formTypeId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   }
  //   return this.http.get(url, httpOptions)
  //     .pipe(
  //       map(this.extractData),
  //       catchError(error => {
  //         if (error.error instanceof ErrorEvent) {
  //           // A client-side or network error occurred. Handle it accordingly.
  //           console.error('An error occurred:', error.error.message);
  //         } else {
  //           // The backend returned an unsuccessful response code.
  //           // The response body may contain clues as to what went wrong,
  //           console.error(
  //             `Backend returned code ${error.status}, ` +
  //             `body was: ${error.error}`);
  //           if (error.status === 403) {
  //             window.dispatchEvent(new CustomEvent('user:force-logout'));
  //           }
  //         }
  //         // return an observable with a user-facing error message
  //         return throwError(
  //           'Something bad happened; please try again later.');
  //       })
  //     );
  // }
  
  // getAllSharedWithMePerticularFormIQAC(token: any, userId: any,formTypeId:any): Observable<any> {
  //   let url = CommonService.BASE_URL + '/api/v1/secure/iqac/forms/sharing/shared-with-me/' + userId + '/'+ formTypeId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   }
  //   return this.http.get(url, httpOptions)
  //     .pipe(
  //       map(this.extractData),
  //       catchError(error => {
  //         if (error.error instanceof ErrorEvent) {
  //           // A client-side or network error occurred. Handle it accordingly.
  //           console.error('An error occurred:', error.error.message);
  //         } else {
  //           // The backend returned an unsuccessful response code.
  //           // The response body may contain clues as to what went wrong,
  //           console.error(
  //             `Backend returned code ${error.status}, ` +
  //             `body was: ${error.error}`);
  //           if (error.status === 403) {
  //             window.dispatchEvent(new CustomEvent('user:force-logout'));
  //           }
  //         }
  //         // return an observable with a user-facing error message
  //         return throwError(
  //           'Something bad happened; please try again later.');
  //       })
  //     );
  // }
  getAllSharedWithMeForm(token: any, userId: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/shared-with-me/' + userId ;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  getAllSharedWithMeApprovedForm(token: any, userId: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/shared-with-me-approved/' + userId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  // getAllSharedWithMeApprovedFormIQAC(token: any): Observable<any> {
  //   let url = CommonService.BASE_URL + '/api/v1/secure/iqac/forms/sharing/shared-with-me-approved';
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   }
  //   return this.http.get(url, httpOptions)
  //     .pipe(
  //       map(this.extractData),
  //       catchError(error => {
  //         if (error.error instanceof ErrorEvent) {
  //           // A client-side or network error occurred. Handle it accordingly.
  //           console.error('An error occurred:', error.error.message);
  //         } else {
  //           // The backend returned an unsuccessful response code.
  //           // The response body may contain clues as to what went wrong,
  //           console.error(
  //             `Backend returned code ${error.status}, ` +
  //             `body was: ${error.error}`);
  //           if (error.status === 403) {
  //             window.dispatchEvent(new CustomEvent('user:force-logout'));
  //           }
  //         }
  //         // return an observable with a user-facing error message
  //         return throwError(
  //           'Something bad happened; please try again later.');
  //       })
  //     );
  // }
  // getAllSharedWithMeApprovedFormWebc(token: any, userId: any): Observable<any> {
  //   let url = CommonService.BASE_URL + '/api/v1/secure/webc/forms/sharing/shared-with-me-approved/' + userId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Authorization': 'Bearer ' + token
  //     })
  //   }
  //   return this.http.get(url, httpOptions)
  //     .pipe(
  //       map(this.extractData),
  //       catchError(error => {
  //         if (error.error instanceof ErrorEvent) {
  //           // A client-side or network error occurred. Handle it accordingly.
  //           console.error('An error occurred:', error.error.message);
  //         } else {
  //           // The backend returned an unsuccessful response code.
  //           // The response body may contain clues as to what went wrong,
  //           console.error(
  //             `Backend returned code ${error.status}, ` +
  //             `body was: ${error.error}`);
  //           if (error.status === 403) {
  //             window.dispatchEvent(new CustomEvent('user:force-logout'));
  //           }
  //         }
  //         // return an observable with a user-facing error message
  //         return throwError(
  //           'Something bad happened; please try again later.');
  //       })
  //     );
  // }
  getAllFormForIQAC(token: any,payload:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/lite-info/shared-iqac';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.put(url,payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllFormForIQACPaged(token: any,payload:any, page:any,searchText:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/lite-info/shared-iqac/page';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }),
      params:{
        page: page,
        searchText:searchText
      }
    }
    return this.http.put(url,payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllForwordedFormToWebC(token: any, payload: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/lite-info/forworded-web';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.put(url,payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  getAllSharedByMeForm(token: any, userId: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/shared-by-me/' + userId;


    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
  getAllUserWithSharedForm(token: any, formId: any, userId: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/sharing/get-already-shared-with-user/' + formId + '/' + userId;
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  shareFormToOfficeCo(token: any, payload: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/officeco/forms/sharing/sharefrom';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url, payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
            if(error.status === 422){
              return  this.userService.presentToast("Office-Co yet not added for your college please add and retry","warning");
              }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  shareFormToOfficeAndwebCo(token: any, payload: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/office-web/forms/sharing/sharefrom';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url, payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
            if(error.status === 422){
              return  this.userService.presentToast("Web/Office-Co yet not added for your college please add and retry","warning");
              }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }


  getAllForwordedFormToOfficeCo(token: any, payload: any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/forms/lite-info/forworded-office'
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.put(url,payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

// getAllSharedWithMeApprovedFormOfficeCo(token: any, userId: any): Observable<any> {
//   let url = CommonService.BASE_URL + '/api/v1/secure/officeco/forms/sharing/shared-with-me-approved/' + userId;
//   let httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//       'Authorization': 'Bearer ' + token
//     })
//   }
//   return this.http.get(url, httpOptions)
//     .pipe(
//       map(this.extractData),
//       catchError(error => {
//         if (error.error instanceof ErrorEvent) {
//           // A client-side or network error occurred. Handle it accordingly.
//           console.error('An error occurred:', error.error.message);
//         } else {
//           // The backend returned an unsuccessful response code.
//           // The response body may contain clues as to what went wrong,
//           console.error(
//             `Backend returned code ${error.status}, ` +
//             `body was: ${error.error}`);
//           if (error.status === 403) {
//             window.dispatchEvent(new CustomEvent('user:force-logout'));
//           }
//         }
//         // return an observable with a user-facing error message
//         return throwError(
//           'Something bad happened; please try again later.');
//       })
//     );
// }

getAllTaggedWithMeApprovedForm(token: any, userId: any): Observable<any> {
  let url = CommonService.BASE_URL + '/api/v1/secure/forms/person/tagging/tagged-with-me-approved/' + userId;
  let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  }
  return this.http.get(url, httpOptions)
    .pipe(
      map(this.extractData),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          if (error.status === 403) {
            window.dispatchEvent(new CustomEvent('user:force-logout'));
          }
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      })
    );
}

getAllTaggedWithDepartmentApprovedForm(token: any, departmentId: any): Observable<any> {
  let url = CommonService.BASE_URL + '/api/v1/secure/forms/department/tagging/tagged-with-department-approved/' + departmentId;
  let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    })
  }
  return this.http.get(url, httpOptions)
    .pipe(
      map(this.extractData),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          if (error.status === 403) {
            window.dispatchEvent(new CustomEvent('user:force-logout'));
          }
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      })
    );
}

}

