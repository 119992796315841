import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class EsupportMappingService {

  constructor(private http: HttpClient) { }

  readESupportMetricMapping(): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/public/metadata/metric/all';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    }
    return this.http.put(url,'', httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
      
  }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  addNaccMetric(token:any,payload:any): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/secure/nacc/add';
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url, payload,httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }

  readNaccMatricDataMapping(token:any,clgType): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/public/metadata/metric/byCollegeType';
    let payload = {
      collegeType : clgType
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    }
    return this.http.post(url,payload, httpOptions)
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
      
  }
 
}
