<!-- <ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button fill="clear" color="light" (click)="close()">
        <ion-icon color="light" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">User Profile</ion-title>
  </ion-toolbar>
</ion-header> -->

<ion-content>
  <div class="alert alert-primary alert-dismissible fade show text-center" role="alert">
    <ion-icon name="person"></ion-icon> &nbsp; <strong>{{userProfile?.salutation?.label}} {{userProfile?.lastName}}
      {{userProfile?.firstName}} {{userProfile?.middleName}}</strong>
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ion-grid>
    <ion-row class="separator">
      <ion-col size="12" size-md="6">
        <ion-item lines="none">
          <ion-label>
            <h3><i class="fas fa-at"></i>&nbsp;{{userProfile?.emailId}}</h3>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-md="6">
        <ion-item lines="none">
          <ion-label>
            <h3><i class="fab fa-whatsapp"></i>&nbsp;{{userProfile?.mobileNumber}}</h3>
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="12" size-md="6" *ngIf="userProfile?.college">
        <ion-item lines="none">
          <ion-label>
            <h3 *ngIf="userProfile.college">College - {{userProfile?.college?.name}}, {{userProfile?.college?.city}}
            </h3>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-md="6" *ngIf="userProfile?.college">
        <ion-item lines="none">
          <ion-label>
            <h3 *ngIf="userProfile.college">Management - {{userProfile?.college?.managementCommittee?.name}},
              {{userProfile?.college?.managementCommittee?.headOfficeCity}}</h3>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <!-- <ion-row>
  <ion-col size="12">
      <div class="alert alert-secondary text-center" role="alert">
          <i class="fas fa-user-plus"></i> &nbsp; Responsibilities & Designations
      </div>
  </ion-col>
  <ion-col size="12" class="text-center">
      <p><i class="fas fa-info-circle"></i>&nbsp; <small>Add applicable Responsibilities for {{userProfile?.salutation?.label}} {{userProfile?.lastName}} {{userProfile?.firstName}} {{userProfile?.middleName}}.</small></p>
  </ion-col>
</ion-row> -->
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <div *ngIf="userProfile?.responsiblities.length != 0">
            <ion-item *ngFor="let designation of userProfile.responsiblities; let respoI = index">
              <ion-label>
                <h3> {{designation?.designationLabel}}</h3>
              </ion-label>
              <ion-label *ngIf="designation.department != null">
                <h3>{{designation.department.name}}</h3>
              </ion-label>
              <ion-label *ngIf="designation.criteria != null">
                <h3>{{designation.criteria}}</h3>
              </ion-label>
              <ion-button style="float: right;" size="small" *ngIf="!userProfile.deleted"
                [disabled]="designation.designation =='IQAC_ADMIN' && loggedInUser.id==userProfile.id" fill="none"
                (click)="removeResponsiblity(userProfile,respoI)">
                <i class="fas fa-trash"></i>
              </ion-button>
            </ion-item>
          </div>
          <!-- <div *ngIf="ADD_DEPARTMENT"> -->
          <div *ngIf="metadata?.disignations?.length>0 && !userProfile.deleted">
            <ion-row>
              <ion-col size="6">
                <select placeholder="Select Role" [(ngModel)]="responsiblity.designation" interface="popover"
                  (change)="designationChange()" class="common-select">
                  <option value="" selected disabled>Select Role</option>
                  <option *ngFor="let desg of metadata.disignations" value="{{desg.value}}">{{desg.label}}
                  </option>
                </select>
              </ion-col>
              <ion-col size="6" *ngIf="IS_DEPARTMENTMENDATORY && !isDefaultDepartment">
                <select placeholder="Select Department" [(ngModel)]="responsiblity.departmentId" interface="popover"
                  (change)="onDepartmentChanged()" class="common-select">
                  <option value="" selected disabled>Select Department</option>
                  <option *ngFor="let dept of departments" value="{{dept.id}}">{{dept.name}}
                  </option>
                </select>
              </ion-col>
              <ion-col size="6" *ngIf="IS_CRITERIA_MANDATORY">
                <select placeholder="Select Criteria" [(ngModel)]="responsiblity.criteria" interface="popover"
                  (change)="onCriteriaChanged()" class="common-select">
                  <option value="" selected disabled>Select Criteria</option>
                  <option *ngFor="let criteria of criteriasFiltered" value="{{criteria}}">{{criteria}}
                  </option>
                </select>
              </ion-col>
              <!--           <ion-col size="6" *ngIf="IS_DEPARTMENTMENDATORY && isDefaultDepartment ">
            <ion-item>
              <ion-label>
                <h3> {{defaultDepartment}}</h3>
              </ion-label>
            </ion-item>
          </ion-col> -->
            </ion-row>
          </div>
          <div>
            <!-- <ion-button *ngIf="!ADD_DEPARTMENT" style="float: right;" fill="outline" size="small" (click)="addResponsiblity()">
        <ion-icon name="add"></ion-icon></ion-button> -->
            <div>
              <ion-button style="float: right;" fill="outline" size="small" (click)="close()">
                Close</ion-button>

              <ion-button style="float: right;" fill="outline" size="small" (click)="addResponsiblityInList()">
                Add</ion-button>


            </div>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" size-md="4"></ion-col>
      <ion-col size="12" size-md="4"></ion-col>
      <!-- <ion-col size="12" size-md="4" class="ion-text-center">
    <button (click)="updateProfile()" type="submit" class="btn btn-block btn-info mt-2">
        Update Profile&nbsp; <i class="fas fa-paper-plane"></i>
      </button> -->
      <!-- </ion-col> -->
    </ion-row>
  </ion-grid>
</ion-content>