import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../../forms/footerSection/footer/footer.component';
import { EsupportSectionComponent } from '../../forms/esupport/esupport-section/esupport-section.component';
import { WebCoordinatorSectionComponent } from '../../forms/webCoordinator/web-coordinator-section/web-coordinator-section.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AttachmentPickerSectionComponent } from '../../forms/attachmentPicker/attachment-picker-section/attachment-picker-section.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HeaderComponent } from '../../forms/headerSection/header/header.component';
import { IqacFromsFooterComponent } from '../../forms/iqac-froms-footer/iqac-froms-footer.component';
import { AttachmentDocumentSectionComponent } from '../../forms/attachmentPicker/attachment-document-section/attachment-document-section.component';
import { OfficeAttachmentSectionComponent } from '../../forms/attachmentPicker/office-attachment-section/office-attachment-section.component';
import { CommonFieldComponent } from '../../forms/commonFieldSection/common-field/common-field.component';
import { DeptFieldsComponent } from '../../forms/commonFieldSection/dept-fields/dept-fields.component';
import { CommitteeFieldComponent } from '../../forms/commonFieldSection/committee-field/committee-field.component';
import { InvolvedStaffFieldComponent } from '../../forms/commonFieldSection/involved-staff-field/involved-staff-field.component';
import { AdminRemarksComponent } from '../../forms/commonFieldSection/admin-remarks/admin-remarks.component';



@NgModule({
  declarations: [EsupportSectionComponent, WebCoordinatorSectionComponent,AttachmentPickerSectionComponent,
     FooterComponent,HeaderComponent,IqacFromsFooterComponent, AttachmentDocumentSectionComponent, OfficeAttachmentSectionComponent,CommonFieldComponent,
     DeptFieldsComponent, CommitteeFieldComponent, InvolvedStaffFieldComponent, AdminRemarksComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    NgxDropzoneModule
  ],
  exports: [EsupportSectionComponent, WebCoordinatorSectionComponent, 
    FooterComponent, CommonModule, IonicModule, AttachmentPickerSectionComponent, AttachmentDocumentSectionComponent, OfficeAttachmentSectionComponent, 
    HeaderComponent, IqacFromsFooterComponent,CommonFieldComponent, DeptFieldsComponent, CommitteeFieldComponent, InvolvedStaffFieldComponent,AdminRemarksComponent],
})
export class SharedModule { }
