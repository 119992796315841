import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from 'src/app/providers/userData/user-data.service';

/**
 * Guards access to Login and Signup page when already logged in.
 */
@Injectable({
  providedIn: 'root'
})
export class LoggedInAuthGuardGuard implements CanActivate {

  constructor(
    public router :  Router,
    public userDataService : UserDataService) {}

  canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return this.userDataService.isLoggedIn().then(loggedIn=>{
      if(loggedIn){
        this.router.navigate(['/home']);
        return false;
      } else {
        return true;
      }
    });
  }
  
}
