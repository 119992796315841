import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInAuthGuardGuard } from './guards/auth/logged-in-auth-guard.guard';
import { LoggedOutAuthGuard } from './guards/auth/logged-out-auth.guard';
import { SubscriptionStatusGuard } from './guards/subscription-status/subscription-status.guard';
import { SuperUserRoleCheckGuard } from './guards/superAdminRoleCheck/super-user-role-check.guard';
import { SuperAdminNiqacAdminRoleCheckGuard } from './guards/superAdminNiqacAdminRoleCheck/super-admin-niqac-admin-role-check.guard';
import { IqacAdminRoleCheckGuard } from './guards/iqacAdminRoleCheck/iqac-admin-role-check.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule),
    canActivate: [LoggedInAuthGuardGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [LoggedInAuthGuardGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'invitation',
    loadChildren: () => import('./pages/invitation/invitation.module').then( m => m.InvitationPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard,SuperAdminNiqacAdminRoleCheckGuard]
  },
  {
    path: 'activation',
    loadChildren: () => import('./pages/activation/activation.module').then( m => m.ActivationPageModule),
    canActivate: [LoggedOutAuthGuard,SuperUserRoleCheckGuard]
  },
  {
    path: 'college-committte-mangement',
    loadChildren: () => import('./pages/collage-committte-mangement/collage-committte-mangement.module').then( m => m.CollageCommittteMangementPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard,IqacAdminRoleCheckGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'departmet-management',
    loadChildren: () => import('./pages/departmet-management/departmet-management.module').then( m => m.DepartmetManagementPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard,IqacAdminRoleCheckGuard]
  },
  {
    path: 'management-committee',
    loadChildren: () => import('./pages/management-committee/management-committee.module').then( m => m.ManagementCommitteePageModule),
    canActivate: [LoggedOutAuthGuard, SuperUserRoleCheckGuard]
  },
  {
    path: 'staff-management',
    loadChildren: () => import('./pages/staff-management/staff-management.module').then( m => m.StaffManagementPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard,IqacAdminRoleCheckGuard]
  },
  {
    path: 'profile-model',
    loadChildren: () => import('./pages/profile-model/profile-model.module').then( m => m.ProfileModelPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  // {
  //   path: 'enquiry',
  //   loadChildren: () => import('./pages/enquiry/enquiry.module').then( m => m.EnquiryPageModule)
  // },
  // {
  //   path: 'enquiry-manangement',
  //   loadChildren: () => import('./pages/enquiry-manangement/enquiry-manangement.module').then( m => m.EnquiryManangementPageModule),
  //   canActivate: [LoggedOutAuthGuard]
  // },
  {
    path: 'demo-enquiry',
    loadChildren: () => import('./pages/demo-enquiry/demo-enquiry.module').then( m => m.DemoEnquiryPageModule),
    canActivate: [LoggedOutAuthGuard,SuperUserRoleCheckGuard]
  },
  {
    path: 'personal-detail',
    loadChildren: () => import('./pages/forms/personal/personalProfile/personalDetail/view-personal-detail/view-personal-detail.module').then( m => m.ViewPersonalDetailPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'new-personal-detail',
    loadChildren: () => import('./pages/forms/personal/personalProfile/personalDetail/new-personal-detail/new-personal-detail.module').then( m => m.NewPersonalDetailPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'new-educational-qualification',
    loadChildren: () => import('./pages/forms/personal/personalProfile/educationalQalification/new-educational-qualification/new-educational-qualification.module').then( m => m.NewEducationalQualificationPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'educational-qualification',
    loadChildren: () => import('./pages/forms/personal/personalProfile/educationalQalification/view-educational-qualification/view-educational-qualification.module').then( m => m.ViewEducationalQualificationPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'form-navigation/:id/:deptId',
    loadChildren: () => import('./pages/forms/form-navigation/form-navigation/form-navigation.module').then( m => m.FormNavigationPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard]
  },
  {
    path: 'form-sharing',
    loadChildren: () => import('./pages/forms/formSharing/form-sharing/form-sharing.module').then( m => m.FormSharingPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'shared-form-inbox/:deptId',
    loadChildren: () => import('./pages/forms/formSharing/shared-form-inbox/shared-form-inbox.module').then( m => m.SharedFormInboxPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'naacmetric',
    loadChildren: () => import('./pages/forms/naacmetric/naacmetric.module').then( m => m.NAACMetricPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'esupport',
    loadChildren: () => import('./pages/forms/esupport/esupport.module').then( m => m.ESupportPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'pre-joining',
    loadChildren: () => import('./pages/forms/personal/personalProfile/preJoining/view-pre-joining/view-pre-joining.module').then( m => m.ViewPreJoiningPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'new-pre-joining',
    loadChildren: () => import('./pages/forms/personal/personalProfile/preJoining/new-pre-joining/new-pre-joining.module').then( m => m.NewPreJoiningPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'research-degrees',
    loadChildren: () => import('./pages/forms/personal/personalProfile/researchDegree/view-reaserch-degree/view-reaserch-degree.module').then( m => m.ViewReaserchDegreePageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'new-research-degrees',
    loadChildren: () => import('./pages/forms/personal/personalProfile/researchDegree/new-reaserch-degree/new-reaserch-degree.module').then( m => m.NewReaserchDegreePageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'created-by-me',
    loadChildren: () => import('./pages/forms/created-by-me/created-by-me.module').then( m => m.CreatedByMePageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'view/:formRouteUrl/:formTypeId',
    loadChildren: () => import('./pages/forms/view-form/view-form.module').then( m => m.ViewFormPageModule),
    canActivate: [LoggedOutAuthGuard]
  },
  {
    path: 'new-paper-published-in-journals',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/paper-published/paper-published.module').then( m => m.PaperPublishedPageModule)
  },
  {
    path: 'new-books-authored-edited',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/books-authored/books-authored.module').then( m => m.BooksAuthoredPageModule)
  },
  {
    path: 'new-development-innovative-pedagogy',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/development-innovative-pedagogy/development-innovative-pedagogy.module').then( m => m.DevelopmentInnovativePedagogyPageModule)
  },
  {
    path: 'new-design-new-curricula',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/design-new-curricula/design-new-curricula.module').then( m => m.DesignNewCurriculaPageModule)
  },
  {
    path: 'new-mooc',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/mooc/mooc.module').then( m => m.MOOCPageModule)
  },
  {
    path: 'new-development-e-content',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/development-of-econtent/development-of-econtent.module').then( m => m.DevelopmentOfEContentPageModule)
  },
  {
    path: 'new-academic-research-guidance',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/research-guidence/research-guidence.module').then( m => m.ResearchGuidencePageModule)
  },
  {
    path: 'new-research-projects',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/research-projects/research-projects.module').then( m => m.ResearchProjectsPageModule)
  },
  {
    path: 'new-consultancy',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/consultancy/consultancy.module').then( m => m.ConsultancyPageModule)
  },
  {
    path: 'new-patents',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/petents/petents.module').then( m => m.PetentsPageModule)
  },
  {
    path: 'new-policy-document',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/policy-documents/policy-documents.module').then( m => m.PolicyDocumentsPageModule)
  },
  {
    path: 'new-Awards-Recognition-Prizes',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/awards-recognition-prizes/awards-recognition-prizes.module').then( m => m.AwardsRecognitionPrizesPageModule)
  },
  {
    path: 'new-Fellowship-Scholarship',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/fellowship-scholarship/fellowship-scholarship.module').then( m => m.FellowshipScholarshipPageModule)
  },
  {
    path: 'new-seminars-conferences-workshops',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/seminars-conferences-workshops/seminars-conferences-workshops.module').then( m => m.SeminarsConferencesWorkshopsPageModule)
  },
  {
    path: 'new-resource-person',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/resource-person/resource-person.module').then( m => m.ResourcePersonPageModule)
  },
  {
    path: 'new-bibliometric',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/bibliometric/bibliometric.module').then( m => m.BibliometricPageModule)
  },
  {
    path: 'new-h-index',
    loadChildren: () => import('./pages/forms/personal/academicsAndResearchActivities/h-index/h-index.module').then( m => m.HIndexPageModule)
  },
  {
    path: 'new-post-joining',
    loadChildren: () => import('./pages/forms/personal/personalProfile/postjoining/postjoining.module').then( m => m.PostjoiningPageModule)
  },
  {
    path: 'new-teaching-experience',
    loadChildren: () => import('./pages/forms/personal/personalProfile/teaching-experience/teaching-experience.module').then( m => m.TeachingExperiencePageModule)
  },
  {
    path: 'new-research-guide',
    loadChildren: () => import('./pages/forms/personal/personalProfile/research-guide/research-guide.module').then( m => m.ResearchGuidePageModule)
  },
  {
    path: 'new-research-experience',
    loadChildren: () => import('./pages/forms/personal/personalProfile/research-experience/research-experience.module').then( m => m.ResearchExperiencePageModule)
  },
  {
    path: 'new-field-specialization',
    loadChildren: () => import('./pages/forms/personal/personalProfile/fields-specialization/fields-specialization.module').then( m => m.FieldsSpecializationPageModule)
  },
  {
    path: 'new-orientation-and-other-courses',
    loadChildren: () => import('./pages/forms/personal/personalProfile/participation/participation.module').then( m => m.ParticipationPageModule)
  },
  {
    path: 'new-classes-engaged',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/class-engaged/class-engaged.module').then( m => m.ClassEngagedPageModule)
  },
  {
    path: 'new-personal-time-table',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/personal-time-table/personal-time-table.module').then( m => m.PersonalTimeTablePageModule)
  },
  {
    path: 'new-internal-evaluation',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/continuous-internal-evaluation/continuous-internal-evaluation.module').then( m => m.ContinuousInternalEvaluationPageModule)
  },
  {
    path: 'new-student-centric-method',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/student-centric-teaching-method/student-centric-teaching-method.module').then( m => m.StudentCentricTeachingMethodPageModule)
  },
  {
    path: 'new-ict-resources',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/ict-resources/ict-resources.module').then( m => m.ICTResourcesPageModule)
  },
  {
    path: 'new-mentoring-guidance-counselling',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/student-mentoring/student-mentoring.module').then( m => m.StudentMentoringPageModule)
  },
  {
    path: 'new-administrative-duties',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/administrative-duties/administrative-duties.module').then( m => m.AdministrativeDutiesPageModule)
  },
  {
    path: 'new-exam-duties',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/exam-duties/exam-duties.module').then( m => m.ExamDutiesPageModule)
  },
  {
    path: 'new-membership-imp-bodies',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/membership-of-important-bodies/membership-of-important-bodies.module').then( m => m.MembershipOfImportantBodiesPageModule)
  },
  {
    path: 'new-supporting-staff',
    loadChildren: () => import('./pages/forms/department/facultyAndWorkload/supporting-staff/supporting-staff.module').then( m => m.SupportingStaffPageModule)
  },
  {
    path: 'new-other-teaching-faculty',
    loadChildren: () => import('./pages/forms/department/facultyAndWorkload/other-teaching-faculty/other-teaching-faculty.module').then( m => m.OtherTeachingFacultyPageModule)
  },
  {
    path: 'new-teaching-posts',
    loadChildren: () => import('./pages/forms/department/facultyAndWorkload/teaching-posts/teaching-posts.module').then( m => m.TeachingPostsPageModule)
  },
  {
    path: 'new-department-workload',
    loadChildren: () => import('./pages/forms/department/facultyAndWorkload/workload/workload.module').then( m => m.WorkloadPageModule)
  },
  {
    path: 'new-infrastructure',
    loadChildren: () => import('./pages/forms/department/facultyAndWorkload/infrastructure-and-equipment/infrastructure-and-equipment.module').then( m => m.InfrastructureAndEquipmentPageModule)
  },
  {
    path: 'new-physical-area',
    loadChildren: () => import('./pages/forms/department/facultyAndWorkload/physical-area-of-department/physical-area-of-department.module').then( m => m.PhysicalAreaOfDepartmentPageModule)
  },
  {
    path: 'new-programmes-offered-and-outcome',
    loadChildren: () => import('./pages/forms/department/PO-CO-Feedback/programmes-offered/programmes-offered.module').then( m => m.ProgrammesOfferedPageModule)
  },
  {
    path: 'new-courses-and-outcome',
    loadChildren: () => import('./pages/forms/department/PO-CO-Feedback/courses-and-course-outcomes/courses-and-course-outcomes.module').then( m => m.CoursesAndCourseOutcomesPageModule)
  },
  {
    path: 'new-feedback-design-0f-syllabus',
    loadChildren: () => import('./pages/forms/department/PO-CO-Feedback/feedback-for-design-syllabus/feedback-for-design-syllabus.module').then( m => m.FeedbackForDesignSyllabusPageModule)
  },
  {
    path: 'new-student-progression-higher-education',
    loadChildren: () => import('./pages/forms/department/studentProgresion/higher-education/higher-education.module').then( m => m.HigherEducationPageModule)
  },
  {
    path: 'new-student--qualifying-important-exam',
    loadChildren: () => import('./pages/forms/department/studentProgresion/important-examinations/important-examinations.module').then( m => m.ImportantExaminationsPageModule)
  },
  {
    path: 'iqac-dashboard',
    loadChildren: () => import('./pages/forms/iqac-dashboard/iqac-dashboard.module').then( m => m.IqacDashboardPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard,IqacAdminRoleCheckGuard]
  },
  {
    path: 'new-accreditation-details',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/accreditation-detail/accreditation-detail.module').then( m => m.AccreditationDetailPageModule)
  },
  {
    path: 'new-establishment-of-IQAC',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/establishment-of-iqac/establishment-of-iqac.module').then( m => m.EstablishmentOfIQACPageModule)
  },
  {
    path: 'new-iqa-system',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/iqa-system/iqa-system.module').then( m => m.IqaSystemPageModule)
  },
  {
    path: 'new-iqac-meetings',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/iqac-meetings/iqac-meetings.module').then( m => m.IqacMeetingsPageModule)
  },
  {
    path: 'new-significant-contributions-by-IQAC',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/significant-contribution/significant-contribution.module').then( m => m.SignificantContributionPageModule)
  },
  {
    path: 'new-plan-of-action-by-IQAC',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/plan-of-action/plan-of-action.module').then( m => m.PlanOfActionPageModule)
  },
  {
    path: 'new-visit-of-NAAC-or-other-body',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/visit-of-naac/visit-of-naac.module').then( m => m.VisitOfNaacPageModule)
  },
  {
    path: 'new-aishe-and-mis',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/aisheand-mis/aisheand-mis.module').then( m => m.AISHEandMISPageModule)
  },
  {
    path: 'new-code-of-conduct',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/code-of-conduct/code-of-conduct.module').then( m => m.CodeOfConductPageModule)
  },
  {
    path: 'new-feedback-collected-analyzed-used-for-improvements',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/feedback-collected/feedback-collected.module').then( m => m.FeedbackCollectedPageModule)
  },
  {
    path: 'web-coordinator-dashboard',
    loadChildren: () => import('./pages/forms/webCoordinator/web-coordinator-dashboard/web-coordinator-dashboard.module').then( m => m.WebCoordinatorDashboardPageModule)
  },
  {
    path: 'new-examinations', //id-64
    loadChildren: () => import('./pages/forms/studentSupport/examinationsAndResults/conduct-of-examination/conduct-of-examination.module').then( m => m.ConductOfExaminationPageModule)
  },
  {
    path: 'new-results', //id-65
    loadChildren: () => import('./pages/forms/studentSupport/examinationsAndResults/results/results.module').then( m => m.ResultsPageModule)
  },
  {
    path: 'new-examination-result-mgmt-system',
    loadChildren: () => import('./pages/forms/studentSupport/examinationsAndResults/examination-management-system/examination-management-system.module').then( m => m.ExaminationManagementSystemPageModule)
  },
  {
    path: 'new-career-guidance', //id-73
    loadChildren: () => import('./pages/forms/studentSupport/careerGuidancePlacements/career-guidance-competitive-exams/career-guidance-competitive-exams.module').then( m => m.CareerGuidanceCompetitiveExamsPageModule)
  },
  {
    path: 'new-placement',  //id-74
    loadChildren: () => import('./pages/forms/studentSupport/careerGuidancePlacements/placement/placement.module').then( m => m.PlacementPageModule)
  },
  {
    path: 'new-placement-details',  //id-75
    loadChildren: () => import('./pages/forms/studentSupport/careerGuidancePlacements/employer-details-pay-packages/employer-details-pay-packages.module').then( m => m.EmployerDetailsPayPackagesPageModule)
  },
  {
    path: 'new-student-mentoring',  // id-76
    loadChildren: () => import('./pages/forms/studentSupport/mentoringCounselling/mentoring/mentoring.module').then( m => m.MentoringPageModule)
  },
  {
    path: 'new-psychological-counselling', //id-77
    loadChildren: () => import('./pages/forms/studentSupport/mentoringCounselling/personal-counseling/personal-counseling.module').then( m => m.PersonalCounselingPageModule)
  },
  {
    path: 'new-complaints-resolutions',   //id-78
    loadChildren: () => import('./pages/forms/studentSupport/grievancesAntiRagging/complaints-resolutions/complaints-resolutions.module').then( m => m.ComplaintsResolutionsPageModule)
  },
  {
    path: 'new-redressal-mechanism',  //id-79
    loadChildren: () => import('./pages/forms/studentSupport/grievancesAntiRagging/guidelines-redressal-mechanisms/guidelines-redressal-mechanisms.module').then( m => m.GuidelinesRedressalMechanismsPageModule)
  },
  {
    path: 'new-student-welfare-schemes', //id-120
    loadChildren: () => import('./pages/forms/studentSupport/welfareScholarships/welfare-schemesfor-students/welfare-schemesfor-students.module').then( m => m.WelfareSchemesforStudentsPageModule)
  },
  {
    path: 'new-scholarship-endowment-monetary-support', //id-127
    loadChildren: () => import('./pages/forms/studentSupport/welfareScholarships/scholarship-freeship-monetary-support/scholarship-freeship-monetary-support.module').then( m => m.ScholarshipFreeshipMonetarySupportPageModule)
  },
  {
    path: 'new-student-satisfaction-survey',  //id-80
    loadChildren: () => import('./pages/forms/studentSupport/student-satisfaction-survey/student-satisfaction-survey.module').then( m => m.StudentSatisfactionSurveyPageModule)
  },
  {
    path: 'new-alumni-association', //id-110
    loadChildren: () => import('./pages/forms/studentSupport/alumni-association/alumni-association.module').then( m => m.AlumniAssociationPageModule)
  },
  { path: 'new-common-form-II',
    loadChildren: () => import('./pages/forms/activities/committeesFormsactivities/common-form2/common-form2.module').then( m => m.CommonForm2PageModule)
  },
  {
    path: 'new-common-form-I',
    loadChildren: () => import('./pages/forms/activities/committeesFormsactivities/common-form1/common-form1.module').then( m => m.CommonForm1PageModule)
  },
  {
    path: 'new-short-term-courses',
    loadChildren: () => import('./pages/forms/activities/shortTermCoursesProjectsInternship/short-term-courses-offered/short-term-courses-offered.module').then( m => m.ShortTermCoursesOfferedPageModule)
  },
  {
    path: 'new-field-work-project-internship-visit',
    loadChildren: () => import('./pages/forms/activities/shortTermCoursesProjectsInternship/field-work/field-work.module').then( m => m.FieldWorkPageModule)
  },
  {
    path: 'new-activities-seminar-conference-workshop',
    loadChildren: () => import('./pages/forms/activities/seminarsW-ShopsConfTrainings/seminars-conferences-workshops/seminars-conferences-workshops.module').then( m => m.SeminarsConferencesWorkshopsPageModule)
  },
  {
    path: 'new-professional-administrative-training',
    loadChildren: () => import('./pages/forms/activities/seminarsW-ShopsConfTrainings/professional-development/professional-development.module').then( m => m.ProfessionalDevelopmentPageModule)
  },
  {
    path: 'new-student-awards',
    loadChildren: () => import('./pages/forms/activities/awardsMedalsPrizes/awards-for-students/awards-for-students.module').then( m => m.AwardsForStudentsPageModule)
  },
  {
    path: 'new-awards-for-institution',
    loadChildren: () => import('./pages/forms/activities/awardsMedalsPrizes/awards-for-institution/awards-for-institution.module').then( m => m.AwardsForInstitutionPageModule)
  },
  {
    path: 'new-MoU-Linkages',
    loadChildren: () => import('./pages/forms/activities/Collaborations/mo-us-and-linkages/mo-us-and-linkages.module').then( m => m.MoUsAndLinkagesPageModule)
  },
  {
    path: 'new-faculty-student-exchange',
    loadChildren: () => import('./pages/forms/activities/Collaborations/faculty-and-student-exchange/faculty-and-student-exchange.module').then( m => m.FacultyAndStudentExchangePageModule)
  },
  {
    path: 'new-research-advisory -committee',
    loadChildren: () => import('./pages/forms/activities/researchInnovationAndEntrepreneurship/research-advisory-committee/research-advisory-committee.module').then( m => m.ResearchAdvisoryCommitteePageModule)
  },
  {
    path: 'new-code-Ethics-in-Research',
    loadChildren: () => import('./pages/forms/activities/researchInnovationAndEntrepreneurship/ethics-committee-for-research/ethics-committee-for-research.module').then( m => m.EthicsCommitteeForResearchPageModule)
  },
  {
    path: 'new-seed-money-for-research',
    loadChildren: () => import('./pages/forms/activities/researchInnovationAndEntrepreneurship/seed-money/seed-money.module').then( m => m.SeedMoneyPageModule)
  },
  {
    path: 'new-innovation-entrepreneurship',
    loadChildren: () => import('./pages/forms/activities/researchInnovationAndEntrepreneurship/innovation-incubation-entrepreneurship/innovation-incubation-entrepreneurship.module').then( m => m.InnovationIncubationEntrepreneurshipPageModule)
  },
  {
    path: 'new-assesing-learning-level',
    loadChildren: () => import('./pages/forms/personal/teachinglearningActivities/assesing-learning-level/assesing-learning-level.module').then( m => m.AssesingLearningLevelPageModule)
  },
  {
    path: 'new-smart-classroom', //90
    loadChildren: () => import('./pages/forms/servicesfacilities/smartClassRooms/smart-class-room/smart-class-room.module').then( m => m.SmartClassRoomPageModule)
  },
  {
    path: 'new-studio-media-centre', //91
    loadChildren: () => import('./pages/forms/servicesfacilities/smartClassRooms/studio-media-center/studio-media-center.module').then( m => m.StudioMediaCenterPageModule)
  },
  {
    path: 'new-computers-IT-infrastructure',
    loadChildren: () => import('./pages/forms/servicesfacilities/smartClassRooms/computer-and-itinfrastructure/computer-and-itinfrastructure.module').then( m => m.ComputerAndITInfrastructurePageModule)
  },
  {
    path: 'new-hostel-brief-description', //97
    loadChildren: () => import('./pages/forms/servicesfacilities/hostel/hostel/hostel.module').then( m => m.HostelPageModule)
  },
  {
    path: 'new-facilities-for-underprivileged-minority-students', //105
    loadChildren: () => import('./pages/forms/servicesfacilities/facilityForWomenPwd/facility-to-minority-student/facility-to-minority-student.module').then( m => m.FacilityToMinorityStudentPageModule)
  },
  {
    path: 'new-facilities-for-women', //103
    loadChildren: () => import('./pages/forms/servicesfacilities/facilityForWomenPwd/facilities-provided-to-women/facilities-provided-to-women.module').then( m => m.FacilitiesProvidedToWomenPageModule)
  },
  {
    path: 'new-disabled-friendly-environment',  //104
    loadChildren: () => import('./pages/forms/servicesfacilities/facilityForWomenPwd/disabled-friendly-environment/disabled-friendly-environment.module').then( m => m.DisabledFriendlyEnvironmentPageModule)
  },
  {
    path: 'new-other-services-facilities', //106
    loadChildren: () => import('./pages/forms/servicesfacilities/otherServicesFacility/other-services-facilities/other-services-facilities.module').then( m => m.OtherServicesFacilitiesPageModule)
  },
  {
    path: 'new-expenditure-infrastructure-augmentation',
    loadChildren: () => import('./pages/forms/officeAndAccounts/grantExpenditure/expediture-on-infra/expediture-on-infra.module').then( m => m.ExpeditureOnInfraPageModule)
  },
  {
    path: 'new-financial-audits',
    loadChildren: () => import('./pages/forms/officeAndAccounts/grantExpenditure/financial-audit/financial-audit.module').then( m => m.FinancialAuditPageModule)
  },
  {
    path: 'new-grants-received-for-endowments-money',
    loadChildren: () => import('./pages/forms/officeAndAccounts/grantExpenditure/fund-recieved/fund-recieved.module').then( m => m.FundRecievedPageModule)
  },
  {
    path: 'new-e-governance-planning-development',
    loadChildren: () => import('./pages/forms/officeAndAccounts/grantExpenditure/implementation-of-egovernance/implementation-of-egovernance.module').then( m => m.ImplementationOfEGovernancePageModule)
  },
  {
    path: 'new-staff-welfare-scheme-teaching-non-teaching',
    loadChildren: () => import('./pages/forms/officeAndAccounts/staffWelfareSchemes/staff-welfare-scheme-teaching-non-teaching/staff-welfare-scheme-teaching-non-teaching.module').then( m => m.StaffWelfareSchemeTeachingNonTeachingPageModule)
  },
  {
    path: 'new-programme-wise-student',
    loadChildren: () => import('./pages/forms/officeAndAccounts/teachingPositionAndStudentStrength/programme-wise-student/programme-wise-student.module').then( m => m.ProgrammeWiseStudentPageModule)
  },
  {
    path: 'new-sanctioned-posts-student-strength',
    loadChildren: () => import('./pages/forms/officeAndAccounts/teachingPositionAndStudentStrength/sanctioned-post/sanctioned-post.module').then( m => m.SanctionedPostPageModule)
  },
  {
    path: 'office-coordinator-dashboard',
    loadChildren: () => import('./pages/forms/office-coordinator-dashboard/office-coordinator-dashboard.module').then( m => m.OfficeCoordinatorDashboardPageModule)
  },
  {
    path: 'new-staff-library',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/staff/staff.module').then( m => m.StaffPageModule)
  },
  {
    path: 'new-space-rooms',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/rooms-utility-space/rooms-utility-space.module').then( m => m.RoomsUtilitySpacePageModule)
  },
  {
    path: 'new-library-infrastructure',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/major-equipment-infrastructure/major-equipment-infrastructure.module').then( m => m.MajorEquipmentInfrastructurePageModule)
  },
  {
    path: 'new-library-facilities-services',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/facilities-services/facilities-services.module').then( m => m.FacilitiesServicesPageModule)
  },
  {
    path: 'new-expenditure',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/resources-expenditure-resources-expenditure/resources-expenditure-resources-expenditure.module').then( m => m.ResourcesExpenditureResourcesExpenditurePageModule)
  },
  {
    path: 'new-library-management-software',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/library-automation-management/library-automation-management.module').then( m => m.LibraryAutomationManagementPageModule)
  },
  {
    path: 'new-library-usage',
    loadChildren: () => import('./pages/forms/libraryKnowledgeResourceCentre/usage-by-teachers-and-students/usage-by-teachers-and-students.module').then( m => m.UsageByTeachersAndStudentsPageModule)
  },
  {
    path: 'new-sport-staff',
    loadChildren: () => import('./pages/forms/physicalEducationSports/staff/staff.module').then( m => m.StaffPageModule)
  },
  {
    path: 'new-space-court-field-ground',
    loadChildren: () => import('./pages/forms/physicalEducationSports/ground-field-courts-utilit-space/ground-field-courts-utilit-space.module').then( m => m.GroundFieldCourtsUtilitSpacePageModule)
  },
  {
    path: 'new-sport-equipment',
    loadChildren: () => import('./pages/forms/physicalEducationSports/major-equipment-infrastructure/major-equipment-infrastructure.module').then( m => m.MajorEquipmentInfrastructurePageModule)
  },
  {
    path: 'new-sport-facilities-services',
    loadChildren: () => import('./pages/forms/physicalEducationSports/facilities-service/facilities-service.module').then( m => m.FacilitiesServicePageModule)
  },
  {
    path: 'new-energy-conservation-activities',
    loadChildren: () => import('./pages/forms/greenInitiatives/energy-conservation-activities/energy-conservation-activities.module').then( m => m.EnergyConservationActivitiesPageModule)
  },
  {
    path: 'new-waste-management-facilities',
    loadChildren: () => import('./pages/forms/greenInitiatives/waste-management-facilities/waste-management-facilities.module').then( m => m.WasteManagementFacilitiesPageModule)
  },
  {
    path: 'new-water-management-facilities',
    loadChildren: () => import('./pages/forms/greenInitiatives/water-management-facilities/water-management-facilities.module').then( m => m.WaterManagementFacilitiesPageModule)
  },
  {
    path: 'new-green-campus-initiative',
    loadChildren: () => import('./pages/forms/greenInitiatives/green-campus-initiative/green-campus-initiative.module').then( m => m.GreenCampusInitiativePageModule)
  },
  {
    path: 'new-environment-energy-audits',
    loadChildren: () => import('./pages/forms/greenInitiatives/environment-energy-audits/environment-energy-audits.module').then( m => m.EnvironmentEnergyAuditsPageModule)
  },
  {
    path: 'library-knowledge-resources-dashboard',
    loadChildren: () => import('./pages/forms/library-knowledge-resources-dashboard/library-knowledge-resources-dashboard.module').then( m => m.LibraryKnowledgeResourcesDashboardPageModule)
  },
  {
    path: 'physical-education-and-sports-dashboard',
    loadChildren: () => import('./pages/forms/physical-education-and-sports-dashboard/physical-education-and-sports-dashboard.module').then( m => m.PhysicalEducationAndSportsDashboardPageModule)
  },
  {
    path: 'green-initiatives-dashboard',
    loadChildren: () => import('./pages/forms/green-initiatives-dashboard/green-initiatives-dashboard.module').then( m => m.GreenInitiativesDashboardPageModule)
  },
  {
    path: 'new-quality-initiatives-by-IQAC',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/quality-assurance-inatiative/quality-assurance-inatiative.module').then( m => m.QualityAssuranceInatiativePageModule)
  },
  {
    path: 'new-common-form-iqac',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/common-form-iqac/common-form-iqac.module').then( m => m.CommonFormIQACPageModule)
  },
  {
    path: 'new-best-practices',
    loadChildren: () => import('./pages/forms/iqac/iqacForms/best-practices/best-practices.module').then( m => m.BestPracticesPageModule)
  },
  {
    path: 'facilitator-dashboard',
    loadChildren: () => import('./pages/forms/facilitator/facilitator-dashboard/facilitator-dashboard.module').then( m => m.FacilitatorDashboardPageModule)
  },
  {
    path: 'esupport-detail',
    loadChildren: () => import('./pages/internal/esupportdetail/esupportdetail.module').then( m => m.EsupportdetailPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/reports/report/report.module').then( m => m.ReportPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard]
  },
  {
    path: 'new-common-form3',
    loadChildren: () => import('./pages/forms/activities/committeesFormsactivities/common-form3/common-form3.module').then( m => m.CommonForm3PageModule)
  },
  {
    path: 'year-management',
    loadChildren: () => import('./pages/year-management/year-management.module').then( m => m.YearManagementPageModule),
    canActivate: [LoggedOutAuthGuard,SubscriptionStatusGuard,IqacAdminRoleCheckGuard]
  },
  {
    path: 'new-research-fellows',
    loadChildren: () => import('./pages/forms/department/research/research-fellows/research-fellows.module').then( m => m.ResearchFellowsPageModule)
  },
  {
    path: 'new-research-facilities',
    loadChildren: () => import('./pages/forms/department/research/research-facilities/research-facilities.module').then( m => m.ResearchFacilitiesPageModule)
  },
  {
    path: 'new-recognition-by',
    loadChildren: () => import('./pages/forms/department/research/recognition-by/recognition-by.module').then( m => m.RecognitionByPageModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/forms/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule)
  },
  {
    path: 'criteria-dashboard/:criteria',
    loadChildren: () => import('./pages/forms/criteria-dashboard/criteria-dashboard.module').then( m => m.CriteriaDashboardPageModule)
  },
  {
    path: 'form-search-model',
    loadChildren: () => import('./form-search-model/form-search-model.module').then( m => m.FormSearchModelPageModule)
  },

  // {
  //   path: 'activation',
  //   loadChildren: () => import('./pages/activation/activation.module').then( m => m.ActivationPageModule)
  // }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
