import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-terms-and-condition-model',
  templateUrl: './terms-and-condition-model.page.html',
  styleUrls: ['./terms-and-condition-model.page.scss'],
})
export class TermsAndConditionModelPage implements OnInit {

  activeSegment = 'terms';
  @Input() q: string;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if(_.includes(this.q, 'q=privacy-policy')) {
      this.activeSegment = 'privacy';
    } else if(_.includes(this.q, 'q=refund-policy')) {
      this.activeSegment = 'refund';
    } else {
      this.activeSegment = 'terms';
    }
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }
}
