import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserDataService } from '../userData/user-data.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(private http: HttpClient,
    public userService : UserDataService,
    // public router: Router,
    // public toastController : to
  ) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getMetadata(): Observable<any> {
    let url = CommonService.BASE_URL + '/api/v1/public/metadata/fetch';
 
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(url, {headers:headers})
      .pipe(
        map(this.extractData),
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
            if (error.status === 403) {
              window.dispatchEvent(new CustomEvent('user:force-logout'));
            }
          }
          // return an observable with a user-facing error message
          return throwError(
            'Something bad happened; please try again later.');
        })
      );
  }
}
