import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from 'src/app/providers/userData/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class IqacAdminRoleCheckGuard implements CanActivate {
  constructor(public userDataService: UserDataService, public router: Router) { }
  profile: any;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userDataService.getLoggedInUserRole().then((roles) => {
      if (roles) {
        this.fetchuserProfile();
        if (roles.includes("IQAC_ADMIN")) {

          return true;
        } else {

          this.router.navigate(['/home']);
          return false;
        }
      }
    });
  }

  fetchuserProfile() {
    this.userDataService.getUserProfile().then((profile) => {
      if (profile) {
        this.profile = profile;

      }
    });
  }

}
